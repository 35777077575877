import { createSlice } from "@reduxjs/toolkit";

let initialState = {
    subjectId: '01-physics',
    sectionId: '01-mechanics',
    labId: '',
    audioContextData: null,
    testExecuteData: null,
    isRefreshNBarCounters: false,
};

const tutorialSlice = createSlice({
  name: "tutorials",
  initialState,
  reducers: {
    setTutorialSubjectId(state, action) {
      state.subjectId = action.payload;
    },
    setTutorialSectionId(state, action) {
      state.sectionId = action.payload;
    },
    setTutorialLabId(state, action) {
      state.labId = action.payload;
    },
    setAudioContextData(state, action) {
      state.audioContextData = action.payload;
    },
    setTestExecuteData(state, action) {
      state.testExecuteData = action.payload;
    },
    setIsRefreshNBarCounters(state, action) {
      state.isRefreshNBarCounters = action.payload;
    },
  },
});

export const getTutorialSubjectId = (state) => state.tutorialSlice.subjectId;
export const getTutorialSectionId = (state) => state.tutorialSlice.sectionId;
export const getTutorialLabId = (state) => state.tutorialSlice.labId;
export const getAudioContextData = (state) => state.tutorialSlice.audioContextData;
export const getTestExecuteData = (state) => state.tutorialSlice.testExecuteData;
export const getIsRefreshNBarCounters = (state) => state.tutorialSlice.isRefreshNBarCounters;

export const { setTutorialSubjectId, setTutorialSectionId, setTutorialLabId, 
  setAudioContextData, setTestExecuteData, setIsRefreshNBarCounters } = tutorialSlice.actions;
export default tutorialSlice.reducer;
