import React, {useState, useEffect} from "react";
import NotebookSelectHomeWorkDlg from './NotebookSelectHomeWorkDlg';
import {storageClass} from "../../redux/slices/storage";
import {fullName, isAuth} from "../../helpers/auth";
import ModalConfirmDialog from "../ui/ModalDialogs/ModalConfirmDialog";
import {chkItemText, chkItemDate} from "../ui/utils/gen_utils";
import {toast} from "react-toastify";
import {SCORE_MANUAL_CHECK} from '../ui/utils/score_utils';
import NotebooksStudentInitDlg from './NotebooksStudentInitDlg';
import {Tabs} from '../ui';
import Table from "../ui/Table/Table";
import {printOnlyDate} from "../../helpers/text";
import {NB_REVIEW_TAB, NB_HOME_WORK_STATUS, NB_ACT, TAB_STATE, getSubjectById, addNotebook, 
    getTaskNumberByTaskSamples, getNotebooks, getAllOwnersNotebookInfoByNotebookId, subjName, 
    getNbDeleteMessage, deleteNotebookAndAllDataByNbId, getNbCopy } from "./nb_utils";
import SelectNotebookFilterOptions  from "./SelectNotebookFilterOptions";
import {useDispatch, useSelector} from "react-redux";
import {Button} from "../ui";
import {setNbStorage} from "./NotebookStorage";
import { Back, Content, ContentBody, ContentFooter, ContentHead, ContentHeader } from "../template/ContentParts";
import "../template/Dashboard/Dashboard.scss";
import nb from "./Notebooks.module.scss";
import "./Notebooks.scss";

const NotebooksOnSubject = ({history, subjectId}) => { //isShowNotebookCatalog, 
	const [notebookTableData, setNotebookTableData] = useState([]);
	const [notebookFilteredTableData, setNotebookFilteredTableData] = useState([]);
	const [isUpdate, setIsUpdate] = useState(false);
    const [shownNotebooks, setShownNotebooks] = useState(NB_REVIEW_TAB.RUNNING);
	const [isShowNotebookCatalog, setIsShowNotebookCatalog] = useState(true);
    //
	const [idBeDeleted, setIdBeDeleted] = useState(undefined);
    const [confirmQuestion, setConfirmQuestion] = useState('');
	const [deletionConfirmed, setDeletionConfirmed] = useState(false);
    const [checkTestInUse, setCheckTestInUse] = useState(false);
    const [isTestinUse, setIsTestinUse] = useState(false);
	const [showConfirmDlg, setShowConfirmDlg] = useState(false);
    const [showSelectHomeWorkDlg, setShowSelectHomeWorkDlg] = useState(false);
    const [showCopiedNameDlg, setShowCopiedNameDlg] = useState(false);
    const [copiedNb, setCopiedNb] = useState(null);
    //
	const [testFilter, setTestFilter] = useState('');
	const [startDate, setStartDate] = useState(null);
	const [finishDate, setFinishDate] = useState(null);
    //
	const [showInitDlg, setShowInitDlg] = useState(false);
	const [selectedIds, setSelectedIds] = useState(undefined);

    const tabs = [
		{tabId: NB_REVIEW_TAB.RUNNING, tabName: 'Текущие'},
		{tabId: NB_REVIEW_TAB.HISTORY, tabName: 'История'},
		{tabId: NB_REVIEW_TAB.BE_CHECKED, tabName: 'На проверке'},
	];

    const dispatch = useDispatch();
    const documentClass = useSelector(storageClass);

    useEffect(() => {
        const getUncheckedResults = async (notebookId) => {
            const result = await getAllOwnersNotebookInfoByNotebookId(notebookId);
            return result.data.filter(item => item.score === SCORE_MANUAL_CHECK).length;
        };

        const fetchData = async () => {
			const id = isAuth()._id;
            let data = await getNotebooks(id, documentClass._id);
            if (isShowNotebookCatalog) {
				//оставляем только новые задания
                data = data.filter(item => item.homeWorkStatus === NB_HOME_WORK_STATUS.CATALOG);
            } else {
				//оставляем только запущенные или завершенные задания
                data = data.filter(item => item.homeWorkStatus !== NB_HOME_WORK_STATUS.CATALOG);
            }

            const list = [];
            for (let i = 0; i < data.length; i ++) {
                const nb = data[i];

                if (nb.titles.subjectId === subjectId) {  
                    let beManuallyScored = false;
                    let nonChecked = '';
                    if (!nb.settings.isAutoScore) {
                        nonChecked = await getUncheckedResults(nb._id);
                        beManuallyScored = nonChecked !== 0;
                    }
    
                    const record = {
                        nameLink: nb.titles.homeWorkName,
                        item: nb.titles.homeWorkItem ? nb.titles.homeWorkItem : '',
                        author: fullName(nb.owner, 'Шаблон'),
                        dateStart: nb.run ? printOnlyDate(nb.run.runFullDate) : '',
                        dateFinish: nb.run ? printOnlyDate(nb.run.completeFullDate) : '',
                        checkType: nb.settings.isAutoScore ? 'Автоматическая' : 'Ручная',
                        tasks: getTaskNumberByTaskSamples(nb.taskSamples)  + ' шт',
                        nonChecked: nonChecked,
                        actions: '',
                        id: nb._id,
                        //
                        beManuallyScored: beManuallyScored,
                        filter_dateStart: nb.run ? nb.run.runFullDate : '',
                        filter_dateFinish: nb.run ? nb.run.completeFullDate : '',
                    };
    
                    list.push(record);
                }
            }

			setNotebookTableData(list);
			setIsUpdate(false);
        }

		fetchData();
	},[documentClass, isShowNotebookCatalog, subjectId, isUpdate]);

    const checkRowDates = (row, nbSelect, filterStartDate, filterFinishDate, beManuallyScored) => {
        const todayDate = new Date();
        const today = todayDate.valueOf();
        const nbStartDate = new Date(row.filter_dateStart);
        const start = nbStartDate.valueOf();
        let nbFinishDate = new Date(row.filter_dateFinish);
        const finish = nbFinishDate.valueOf();

        const isFuture = today <= start;
        const isManuallyScored = !isFuture && beManuallyScored;

        //1 - check criteria for these tabs
        let found = false;
        if (nbSelect === NB_REVIEW_TAB.RUNNING && today < finish && !isManuallyScored) {
            found = true;
        } else if (nbSelect === NB_REVIEW_TAB.HISTORY && today >= finish && !isManuallyScored) {
            found = true;
        } else if (nbSelect === NB_REVIEW_TAB.BE_CHECKED && isManuallyScored) {
            found = true;
        }
    
        //2 - check filtering settings for start date
        if (found && filterStartDate && !chkItemDate(row.filter_dateStart, filterStartDate, true)) {
            found = false;
        } else if (found && filterFinishDate && !chkItemDate(row.filter_dateFinish, filterFinishDate, false)) {
            found = false;
        }
        
        return found;
    };
    
    useEffect(() => {
        if (isShowNotebookCatalog) {
            setNotebookFilteredTableData(notebookTableData);
            return;
        }

		const list = [];

		for (let i = 0; i < notebookTableData.length; i ++) {
			const record = notebookTableData[i];
            const beManuallyScored = record.beManuallyScored;
            let found = checkRowDates(record, shownNotebooks, startDate, finishDate, beManuallyScored);

			if (found && (chkItemText(record, 'nameLink', testFilter) || chkItemText(record, 'item', testFilter))) {
				list.push(record);
            }
		}

		setNotebookFilteredTableData(list);
    }, [notebookTableData, testFilter, startDate, finishDate, shownNotebooks, isShowNotebookCatalog]);

	useEffect(() => {
		if (!deletionConfirmed) return;

        deleteNotebookAndAllDataByNbId(idBeDeleted, setIsUpdate);

		setDeletionConfirmed(false);
		setIdBeDeleted(undefined);
		setIsTestinUse(false);
		setCheckTestInUse(false);
	}, [deletionConfirmed, idBeDeleted, isTestinUse, dispatch]);

    useEffect(() => {
		if (!checkTestInUse) return;
        setShowConfirmDlg(true);
    }, [checkTestInUse, idBeDeleted]);
    
	const handleEditTest = id => {
		setNbStorage({});
        history.push('/notebooks/' + NB_ACT.ADD_EDIT_NB + '/' + id);
	};

	const handleSetTestStartTime = id => { //переход к последнему табу
        history.push('/notebooks/' + NB_ACT.SET_RUN_TIME + '/' + id);
	};

    const canEditIDs = () => notebookFilteredTableData.filter(item => isAuth().role === 3 || item.author !== 'Шаблон').map(item => item.id);

    //copying: step 1 - create a new nb
	const handleStartCopyNb = id => {
        getNbCopy(id, documentClass._id, setCopiedNb);
	};
    //copying: step 2 - open the dlg to update name and item
	useEffect(() => {
		if (!copiedNb) return;
        setShowCopiedNameDlg(true);
	}, [copiedNb]);
    //copying: step 3 - save the updated name and item
    const handleCompleteCopyNb = (name, item) => {
        const doCopy = async () => {
            setShowCopiedNameDlg(false);
            copiedNb.titles.homeWorkName = name;
            copiedNb.titles.homeWorkItem = item;
            //save the nb copy
            await addNotebook(copiedNb);
            setIsUpdate(true);
            setCopiedNb(null);
            toast.success("Рабочая тетрадь скопирована."); 
        };
        doCopy();
    };
    //copying: step 4 - close the dlg if cancel
    const doCloseCopiedNameDlg = () => {
        setCopiedNb(null);
        setShowCopiedNameDlg(false);
    };

    //create a new nb: step 1 - create name and item
    const doCreateNewNotebook = (homeWorkName, homeWorkItem) => {
        const data = {
            subjectId: subjectId,
            homeWorkName: homeWorkName,
            homeWorkItem: homeWorkItem
        };
        setNbStorage(data);
        setShowSelectHomeWorkDlg(false);
        history.push('/notebooks/' + NB_ACT.ADD_EDIT_NB);
    };
    //create a new nb: step 2 - close the dlg if cancel
    const doCloseHomeWorkDlg = () => {
        setShowSelectHomeWorkDlg(false);
    };

	const handleRequestDeleteTest = id => {
		const getMsg = async () => {
			const msg = await getNbDeleteMessage(id);
            setIdBeDeleted(id);
            setConfirmQuestion(msg);
            setCheckTestInUse(true);
            };
		getMsg();
	};

	const handleDeleteTestNo = id => {
		setShowConfirmDlg(false);
		setDeletionConfirmed(false);
		setIdBeDeleted(undefined);
		setCheckTestInUse(false);
	};

	const handleDeleteTestYes = () => {
		setShowConfirmDlg(false);
		setDeletionConfirmed(true);
	};

    const handleLink = id => {
        if (!isShowNotebookCatalog)
            history.push('/notebooks/' + NB_ACT.REVIEW_NB_RUN + '/' + id);
        else 
            handleEditTest(id);
    };

	const handleOpenInitDlg = nbId => { //teacher preview
		setSelectedIds('' + nbId + '|'); //2й параметр не передаем
		setShowInitDlg(true);
	};

	const handleExecutePreview = nbId => {
	    history.push('/notebooks/' + NB_ACT.RUN_TEST + '/' + selectedIds);
	};	

	const specialCellStyle = (item, rowInd, colInd, row) => {
		const NON_CHECKED_RES_COL = 7;
		if (colInd === NON_CHECKED_RES_COL) return {color: '#C96203'};
		return {};
	};

    const getHomeWorkTable = () => {
        const headerRunning = [
            {column: 'Название', name: 'nameLink', style: { width: '15%'} }, 
            {column: 'Тема', name: 'item', style: { width: '15%'} }, 
            {column: 'Автор', name: 'author', style: { width: '10%'} }, 
            {column: 'Дата начала', name: 'dateStart', style: { width: '10%'} }, 
            {column: 'Дата окончания', name: 'dateFinish', style: { width: '10%'} }, 
            {column: 'Вид проверки', name: 'checkType', style: { width: '10%'} }, 
            {column: 'Количество заданий', name: 'tasks', style: { width: '12%'} }, 
			{column: 'Непроверенные результаты', name: 'nonChecked', style: { width: '10%'} }, 
            {column: 'Действия', name: 'actions', style: { width: '8%'} }, 
            {column: 'id', name: 'id', style: { width: '0%'} }
        ];
    
        const headerCatalog = [
            {column: 'Название', name: 'nameLink', style: { width: '25%'} }, 
            {column: 'Тема', name: 'item', style: { width: '20%'} }, 
            {column: 'Автор', name: 'author', style: { width: '10%'} }, 
            {column: 'Дата начала', name: 'dateStart', style: { width: '15%'} }, 
            {column: 'Количество заданий', name: 'tasks', style: { width: '20%'} }, 
            {column: 'Действия', name: 'actions', style: { width: '10%'} }, 
            {column: 'id', name: 'id', style: { width: '0%'} }
        ];
    
		const actionRunning = {
            handleEdit: id => handleEditTest(id),
            canEditIDs: canEditIDs(),
            handleCopy: id => handleStartCopyNb(id),
            handleDelete: id => handleRequestDeleteTest(id),
            canDeleteIDs: canEditIDs(),
        };

        let actionCatalog = actionRunning;
        if (isAuth().role < 3) {
    		actionCatalog =  {
                ...actionRunning,
                handleRun: id => handleSetTestStartTime(id),
                tooltipRun: 'Установите время задания и запустите его',
                canRunIDs: canEditIDs(),
				handlePreview: id => handleOpenInitDlg(id),
            };
        }

		return (
		<div className={nb.nb__table}>
			<Table 
				table={{
                    header: !isShowNotebookCatalog ? headerRunning : headerCatalog, 
					data: notebookFilteredTableData,
                    specialCellStyle: specialCellStyle
				}}
				sort={{
                    hasSorting: true, 
                    initSortInd: -4
                }}
                link={{handleLink: id => handleLink(id)}}
                actions={!isShowNotebookCatalog ? actionRunning : actionCatalog}
			/>
		</div>
		);
    };

    let noTasks = false;
    if (notebookTableData.length === 0) {
        noTasks = true;
    }

    return  (
        <>
            <ContentHead flex={false}>
                <Back onClick={() => history.push('/notebooks/' + NB_ACT.SHOW_ALL_NBS)} text="Вернуться ко всем предметам" icon="back" margin="bottom" />
                <div className={nb.nb__title}>
                    {!isShowNotebookCatalog ? 'Список выполненных ' : 'Каталог '} 
                    {isAuth().role < 3 ? 'заданий' : 'шаблонов'} 
                    {' по предмету '}{getSubjectById(subjectId)}
                </div>

                {isAuth().role < 3 &&
				<div className="cor-net__section">
					<Tabs>
						{TAB_STATE.map(tab => (
							<Tabs.Item 
								active={Number(isShowNotebookCatalog) === tab.status}
								onClick={() => setIsShowNotebookCatalog(!!tab.status)}
								key={tab.status}
							>
								{tab.name}
							</Tabs.Item>
						))}
					</Tabs>
				</div>
				}
            </ContentHead>

            <Content background={!noTasks}>
            {notebookTableData.length !== 0 && !isShowNotebookCatalog &&
                    <ContentHeader>
                        <SelectNotebookFilterOptions
                            textPrompt={'Введите название или тему задания'}
                            testFilter={testFilter}
                            setTestFilter={setTestFilter}
                            startDate={startDate}
                            setStartDate={setStartDate}
                            finishDate={finishDate}
                            setFinishDate={setFinishDate}
                            shownNotebooks={shownNotebooks}
                            setShownNotebooks={setShownNotebooks}
                            tabs={tabs}
                        />
                    </ContentHeader>
                }

                <ContentBody scrolled={!noTasks} padding={!noTasks} >
                    <div className={nb.nb__wrap}>
                        <div className="nb__body mt-0">
                            {noTasks ? 
                                <div className={nb.nb__center}>
                                    <div className={nb.nb__info}>
                                        <div className={nb.nb__info_title}>
                                            {"У вас пока нет " + (isShowNotebookCatalog ? 'созданных ' : 'выполненных  ') + 
                                            (isAuth().role < 3 ? "заданий" : "шаблонов") + 
                                            " по предмету " + getSubjectById(subjectId)}.
                                        </div>
                                        <div className={nb.nb__info_description}>
                                            {"Создайте " + subjName() + " по выбранному предмету."}
                                            <br></br>
                                            Вы в любой момент сможете изменить его.
                                        </div>
                                        <div className={nb.nb__info_button}>
                                            <Button type="submit" color="primary" size = "extraSmall" onClick={() => setShowSelectHomeWorkDlg(true)}>
                                                {"Создать " + subjName()}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                                : 
                                <>
                                    {getHomeWorkTable()}
                                </>
                            }
                        </div>
                    </div>
                </ContentBody>

                {notebookTableData.length !== 0 && 
                    <ContentFooter>
                        <Button type="submit" color="primary" size = "extraSmall" onClick={() => setShowSelectHomeWorkDlg(true)}>
                            Создать {subjName()}
                        </Button>
                    </ContentFooter>
                }
            </Content>
      
            {showSelectHomeWorkDlg && 
			<NotebookSelectHomeWorkDlg 
				showModal={showSelectHomeWorkDlg}
                subjectId={subjectId}
                initName={''} 
                initItem={''}
                doSetShowSelectHomeWorkDlg={doCreateNewNotebook}
                doCancelDlg={doCloseHomeWorkDlg}
			/>
            }

            {showCopiedNameDlg && 
			<NotebookSelectHomeWorkDlg 
				showModal={showCopiedNameDlg}
                subjectId={subjectId}
                initName={copiedNb?.titles?.homeWorkName} 
                initItem={copiedNb?.titles?.homeWorkItem}
                doSetShowSelectHomeWorkDlg={handleCompleteCopyNb}
                doCancelDlg={doCloseCopiedNameDlg}
			/>
            }

            {showConfirmDlg &&
			<ModalConfirmDialog
				showConfirmDlg={showConfirmDlg} 
				handleNo={handleDeleteTestNo}
				handleYes={handleDeleteTestYes}
				question={confirmQuestion}
				btnTextYes={'Точно удалить'}
				btnTextNo={'Оставить'}
				redWarning={true}
			/>
            }

            {showInitDlg && 
                <NotebooksStudentInitDlg
                    showDlg={showInitDlg}
                    setShowDlg={setShowInitDlg}
                    selectedIds={selectedIds}
                    handleExecuteTest={handleExecutePreview}
                />
            }
        </>
    );
}

export default NotebooksOnSubject;
