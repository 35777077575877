import React from 'react';
import { Button, Modal } from 'rlabui';
import Icon from '../Icon';
import { VIDEO_SOURCE } from './utils';
import ss from './ChangeVideoSourceDlg.module.scss';

const ChangeVideoSourceDlg = ({ showModal, setShowModal, handleChangeVideoSource }) => {

    const getVideoSourceOption = (optionInd, name, iconName) => {
        return (
            <div className={ss.item} onClick={() => handleChangeVideoSource(optionInd)} key={'st01' + optionInd} >
                <div className={ss.content} key={'st03' + optionInd}>
                    <Icon name={iconName} key={'st04' + optionInd} />
                    <span key={'st05' + optionInd}>{name}</span>
                </div>
            </div>
        );
    };

    return (
        <Modal visible={showModal} className={ss.root} size={'md'}>
            <Modal.Head modalClose={() => setShowModal(false)} title="Заменить видео" />
            <Modal.Body>
                <div className={ss.label}>Выберите источник видео для замены</div>
                <div className={ss.list}>
                    {getVideoSourceOption(VIDEO_SOURCE.VIDEOFILE, 'Открыть видео с компьютера', 'mac')}
                    {getVideoSourceOption(VIDEO_SOURCE.CAMERA, 'Записать видео с микроскопа', 'microscope')}
                </div>
            </Modal.Body>
            <Modal.Footer align="left">
                <Button color="primary" border={true} onClick={() => setShowModal(false)}>Отменить</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ChangeVideoSourceDlg;
