import { Dropdown, Icon } from '../';
import DropdownItem from '../Dropdown/DropdownItem';
import {storageApi} from "../../../api/api";
import axios from "axios";
import {loadFiles} from "../../../redux/slices/lists";
import { toast } from "react-toastify";
import {fullName, profilePicture} from "../../../helpers/auth";
import TextEditor, {BUTTON_LIST} from '../TextEditor/TextEditor';
import htmlParser from "html-react-parser";

const getIsSelected = (list, id, value) => {
    const elem = list?.find(item => item.value === id);
    const foundValue = elem?.value;

    if (foundValue === '' && value === '') 
        return 'selected';
    else 
        return foundValue === value ? 'selected' : '';
};

export const getDropdown = (list, selectedId, handleClick, dropdownClassName, dropdownItemClassName, 
    key, dropPosition="bottom", disabled=false, additionalClickOpt=undefined) => {
	const label = list.find(item => item.value.toString() === selectedId?.toString())?.label;
	const getClassName = v => dropdownItemClassName + ' ' + getIsSelected(list, selectedId, v);

    return (
        <Dropdown 
            value={label} 
            dropPosition={dropPosition}
            className={dropdownClassName}
            disabled={disabled}
        >
            {list.map((item, ind) => 
                <DropdownItem 
                    key={key+ind} onClick={() => handleClick(item.value, additionalClickOpt)} 
                    className={getClassName(item.value)} >
                    {item.label} 
                </DropdownItem>
             )}
        </Dropdown>
    );
};

//=============
const getName = (_files, name) => {
    const checkName = (_files, name) => _files.some(f => printFile(f.Key) === name);

    let newName = name;
    const ext = name.lastIndexOf('.');
    let i = 0;
    while (checkName(_files, newName)) {
        i = i + 1;
        newName = name.slice(0, ext) + " (" + i + ")" + name.slice(ext);
    }
    return newName;
}

export const uploadFile = async (prefix, _files, file, setLoadedFileName, setUploadProgress) => {
    let name = file.name; // ? file.name : file.Key;
    //if (name.includes('/')) name = name.split('/')[1];
    const fName = getName(_files, name);
    const uploadUrl = await storageApi.getUploadUrl(prefix + fName);

    axios
      .put(uploadUrl, file, {
        headers: { 'Content-Type': file.type },
        onUploadProgress: (e) =>
          setUploadProgress(
            Math.min(Math.round((e.loaded * 100) / e.total), 100)
          ),
      })
      .then((res) => {
        setLoadedFileName(fName);
      })
      .catch((err) => {
        toast.error(err);
      });
  };

export const doDeleteFileByName = async (prefix, dispatch, fileName) => {
    const fName = prefix + fileName;
    try {
        await storageApi.delete(fName);
        dispatch(loadFiles(prefix));
    } catch (err) {
        console.log(err);
    }
};

export const printFile = (name) => name.split('/').pop();

const getExtension =(name)=> {
    return name.lastIndexOf('.') > -1 ? name.split('.').pop().toLowerCase() : '';
}

export const fileTypeIcon =(name)=> {
    switch (getExtension(name)) {
        case "csv":
        case "xls":
        case "xlsx":
            return "file-excel";
        case "doc":
        case "docx":
            return "file-word";
        case "ppt":
        case "pptx":
            return "file-pp";
        default:
            return "file";
    }
};
export const isImageFile =(name)=> ['gif', 'jpg', 'jpeg', 'png', 'svg', 'tiff'].includes(getExtension(name));

export const getFiles = (savedFiles, fileNameList) => {
    //we need to find all files from fileNameList that are not removed from the collection on any reason
    if (savedFiles.length === 0 || fileNameList.length === 0) return [];
    const checkedFiles = [];

    for (let k = 0; k < fileNameList.length; k ++) {
        const fn = fileNameList[k].name;

        for (let i = 0; i < savedFiles.length; i ++) {
            const file = savedFiles[i];
            const fileName = file.Key.split('/')[1];    
            if (fileName === fn) 
                checkedFiles.push(file); 
        }
    }

    return checkedFiles; //the files exist in the collection
};


export const registerAddNewFile = (fileName, objFiles, setObjFiles) => {
    const f = {name: fileName, isNew: true, isAdded: true, isDeleted: false};
    const arr = [...objFiles];
    arr.push(f);
    setObjFiles(arr);
};

export const registerDeleteFile = (shortFileName, objFiles, setObjFiles, dispatch, deleteFileByName) => {
    let f = objFiles.find(item => item.name === shortFileName);
    let oFiles = [...objFiles];
    if (!f.isNew) {
        //только помечаем, что файл следует удалить в случае сохранения
        f = {...f, isDeleted: true};
        oFiles = oFiles.map(item => item.name !== shortFileName ? item : f);
    } else {
        //это новый файл, его удаляем сразу
        deleteFileByName(dispatch, f.name);
        oFiles = oFiles.filter(item => item.name !== shortFileName);
    }

    setObjFiles(oFiles);
};

export const base64ToArrayBuffer = (cleanedBase64) => {
	//https://stackoverflow.com/questions/64923029/javascript-how-to-save-base64-data-as-png
	const binary_string = window.atob(cleanedBase64);
	const len = binary_string.length;
	const bytes = new Uint8Array(len);
	for (let i = 0; i < len; i++) 
		bytes[i] = binary_string.charCodeAt(i);
	return bytes.buffer;
}

export const downloadImage = (fileName, imageUrl) => {
		let link = document.createElement('a');
		link.download = fileName;
		link.href = imageUrl;
		link.click();		
};

//https://stackoverflow.com/questions/3665115/how-to-create-a-file-in-memory-for-user-to-download-but-not-through-server
export const downloadTextFile = (filename, text) => {
  var element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', filename);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};
//downloadTextFile('test.txt', 'Hello world!');
//option 2 - 
//const blob = new Blob(["some text"], {    type: "text/plain;charset=utf-8;",});
//saveAs(blob, "thing.txt");

export const urlToFileObject = async(image)=> {
    const response = await fetch(image);
    // here image is url/location of image
    const blob = await response.blob();
    const file = new File([blob], 'composition.jpg', {type: blob.type});
    return file;
    //return response.url;
};

export const fileToDataUri = (file) => new Promise((resolve, reject) => {
    //see also doDownloadResearch in pi_utils
    const reader = new FileReader();
    reader.onload = (event) => {
      resolve(event.target.result)
    };
    reader.readAsDataURL(file);
});

//================

const getDigitFromNumber = num => num - Math.floor(num / 10) * 10;
export const getDayTitle = (i) => {
    if (i > 10 && i <= 20) return 'дней';
    const digit = getDigitFromNumber(i);
    return digit === 1 ? 'день' : digit >= 2 && digit <= 4 ? 'дня' : 'дней';
}
export const getHourTitle = (i) => {
    if (i > 10 && i <= 20) return 'часов';
    const digit = getDigitFromNumber(i);
    return digit === 1 ? 'час' : digit >= 2 && digit <= 4 ? 'часа' : 'часов';
}
export const getMinuteTitle = (i) => {
    if (i > 10 && i <= 20) return 'минут';
    const digit = getDigitFromNumber(i);
    return digit === 1 ? 'минута' : digit >= 2 && digit <= 4 ? 'минуты' : 'минут';
}
export const getSecondTitle = (i) => {
    if (i > 10 && i <= 20) return 'секунд';
    const digit = getDigitFromNumber(i);
    return digit === 1 ? 'секунда' : digit >= 2 && digit <= 4 ? 'секунды' : 'секунд';
}

export const getRemainingTimeInMSec = (currTime, initTime, durInSec) => {
	const durInMSec = durInSec * 1000; 
	const remInMSec = durInMSec - (currTime - initTime);
	return remInMSec;
  }
  
  export const showRemainingTime = (currTime, initTime, durationInSec, isHrMinSec = false) => {
	const getRemTimeInMinSecs = remInMSec => {
	  const mins = Math.floor(remInMSec / 60000);
	  let secs = Math.floor((remInMSec - mins * 60000) / 1000);
	  let strSecs = secs.toString();
	
	  //if (strSecs.length === 1) strSecs = "0" + strSecs;
	  return (<span>Осталось: <b>{mins}</b> мин <b>{strSecs}</b> сек </span>);
	};
  
	const getRemTimeInHrMinSecs = (remInMSec) => showTopBarTime(false, remInMSec);
	
	  if (!currTime || !initTime || !durationInSec) return <span></span>;
  
	const remInMSec = getRemainingTimeInMSec(currTime, initTime, durationInSec);
	if (remInMSec < 0) return (<span>Работа завершена</span>);
	return isHrMinSec ? getRemTimeInHrMinSecs(remInMSec) : getRemTimeInMinSecs(remInMSec);
  };
  
  export const showCurrTime = (currTime, initTime) => {
	  return !currTime || !initTime ? <span></span> : showTopBarTime(true, currTime - initTime);
  };
  
  const showTopBarTime = (isCurrentTime, remInMSec) => {
	//const getTimeStr = val => (val.toString().length === 1 ? "0" : '') + val.toString();
	const getTimeStr = val => val.toString();
  
	let remInSec = Math.floor(remInMSec / 1000);
	const hrs = Math.floor(remInSec / 60 / 60);
	remInSec -= hrs * 60 * 60;
	const mins = Math.floor(remInSec / 60);
	remInSec -= mins * 60;
	const secs = remInSec;
  
	return (
	  <div className='cor-timer'>
		{isCurrentTime ? 
		<span className="cor-timer__label">
		  Прошло времени&nbsp;&nbsp;
		  <Icon name="alarm" className={'labWorkShow__alarm'} />&nbsp;
		</span>
		: 
		<span className="cor-timer__label">Осталось:</span>
		}
		{hrs > 0 &&
		<span className='cor-timer__item hrs'>
		  <span className="cor-timer__number">{getTimeStr(hrs)}</span>
		  <span className="cor-timer__title">{getHourTitle(hrs)}</span>
		</span>
		}
		<span className='cor-timer__item mins'>
		  <span className="cor-timer__number">{getTimeStr(mins)}</span>
		  <span className="cor-timer__title">{getMinuteTitle(mins)}</span>
		</span> 
		<span className='cor-timer__item secs'>
		  <span className="cor-timer__number">{getTimeStr(secs)}</span>
		  <span className="cor-timer__title">{getSecondTitle(secs)}</span>
		</span> 
	  </div>
	);
};
  
//=============

export const initializeTimeCounter = (sec, setTime) => {
	const workProgress = () => { setTime(Date.now()); }
	return setInterval(workProgress, sec * 1000);
};

//=============

export const getClassStudents = (_classUsers) => {
	return _classUsers.filter(user => user.role === 0).map((item, ind) => ({
		owner: item._id, 
		name: fullName(item),
    personPict: item.picture
	}));
};
  
//=============

export const chkItemText = (row, itemName, filter) => {
    const f = filter.trim();
    if (!f) return true;
    if (itemName) {
        const value = row[itemName];
        return value.toLowerCase().includes(f.toLowerCase());
    } else {
        return Object.values(row).some(col => col?.toLowerCase()?.includes(f.toLowerCase()));
    }
};
export const chkItemDate = (filter_date, date, isStart) => {
    if (!date) return true;
    const rowDate = new Date(filter_date);
    const rowValueOf = rowDate.valueOf();

    let checkDate = new Date(date);
    if (!isStart) //add 1 day to the finish date
        checkDate.setDate(checkDate.getDate() + 1);

    const valueOf = checkDate.valueOf();
	return isStart ? rowValueOf >= valueOf : rowValueOf <= valueOf;
}

//===============

export const getRoundValue = (_value, roundNum) => {
	if (_value === undefined) return 0;
	const value = Number(_value);
	if (Math.abs(value) >= 1) {
	  const val0 = Number.parseFloat(value).toFixed(roundNum);
	  return parseFloat(val0);
	}
  
	if (value === 0) return value;
	if (Math.abs(value) < 0.0000001) return '0';
  
	let isReady = false;
	let val = value;
	let max = 1;
	let cnt = 0;
	for (let i = 0; i < roundNum - 1; i++) max *= 10;
	while (!isReady) {
	  val *= 10;
	  cnt ++;
	  if (Math.abs(val) >= max) {
		isReady = true;
		val = Number(Math.round(val).toFixed(0));
	  }
	}
	let zeros = '0.';
	let hasSign = false;
	if (val < 0) {
	  val = -val;
	  hasSign = true;
	}
	for (let i = 0; i < cnt - roundNum; i++) zeros += '0';
	let res = zeros + val;
	if (hasSign) res = "-" + res;
	return res;
  };
  
//=================

export const getNbCheckBox = (value, handle, id, text) => {
    return (
        <div className='cor-net__checkbox' key={'nbc1'+id}>
            <input type="checkbox"
                key={'nbin1'+id}
                id={id} 
                value={value}
                checked={value} 
                onChange={() => handle(!value)}
            >
            </input>
            {!!text && 
            <label htmlFor={id} key={'nbl1'+id}>{text}</label>
            }
        </div>
    );
};    

//==================

export const getLimitedString = (wordLimit, lineLimit, value) => {
  let vl = value;
  const count = lineLimit / wordLimit + 1;
  for (let i = 0; i < count; i ++) vl = chkTextWordSize(wordLimit, vl);
  vl = vl.substring(0, lineLimit);
  return vl;
};

export const chkTextWordSize = (maxLength, v) => {
  const arr = v.split(' ');
  const resArr = [];
  for (let i = 0; i < arr.length; i ++) {
      if (arr[i].length < 2 * maxLength) resArr.push(arr[i]);
      else {
          resArr.push(arr[i].substring(0, maxLength));
          resArr.push(arr[i].substring(maxLength));
      }
  }
  return resArr.join(' ');
};

//==================

export const renderPicture = (u) => u.picture ? <img src={profilePicture(u.picture)} alt={fullName(u)}/> : <Icon name='user'/>;

//==================

export const getDescriptionEditor = (value, setValue, isDisabledTemplate, ind = 0, maxCharCount) => {
  if (!isDisabledTemplate) {
      return (
          <TextEditor 
              className="example"
              value={value} 
              setValue={setValue} 
              maxHeight={'100%'} 
              btListType={BUTTON_LIST.CUSTOM_SCENARIO}
              maxCharCount={maxCharCount}
          />
      );
  }

  return (
      <div className='cor_te__preview' key={'te'+ind}> 
          {value ? htmlParser(value) : ''}
      </div>
  );
};

export const calcDecriptionSize = v => {
  const parent = htmlParser(v);
  return calcPropsSizes(parent);
};

const calcPropsSizes = parent => {
  if (!parent) return 0;
  let sum = 0;

  if (!Array.isArray(parent)) {
      const children = parent.props.children;
      if (typeof children === 'string') {
          const len = children.length;
          return len;
      } else {
          sum += calcPropsSizes(children);
      }
  } else { //array:
      for (let i = 0; i < parent.length; i ++) {
          const p = parent[i];
          if (typeof p === 'string') {
              sum += p.length;
          } else {
              const pp = p.props;
              const children = pp.children;
  
              if (!Array.isArray(children)) {
                  if (children && typeof children === 'string') {
                      sum += children.length;
                  } else if (children?.props?.children) {
                      sum += calcPropsSizes(children);
                  }
              } else {
                  for (let k = 0; k < children.length; k ++) {
                      const child = children[k];
                      if (typeof child === 'string') {
                          sum += child.length;
                      } else {
                          sum += calcPropsSizes(child);
                      }
                  }
              }
          }
      }
  }

  return sum;
};

