import React, { useState, useEffect, useRef } from "react";
import { InputLimit, Icon } from '../ui';
import {Button} from 'rlabui';
import { getYesNoOpts, getDurationList, isDisabled } from "./ot_utils";
import { getDropdown } from "../ui/utils/gen_utils";
import {toast} from "react-toastify";
import ModalConfirmSavingDialog from "../ui/ModalDialogs/ModalConfirmDialog";

const MAX_NAME_LENGTH = 75;
const MAX_DESC_LENGTH = 300;

const OnlineTestCreateGeneral = (props) => {
	const [showSaveAndExitCompleteDlg, setShowSaveAndExitCompleteDlg] = useState(false);
    const [changedData, setChangedData] = useState(false);
	const timerIdRef = useRef();

	useEffect(() => {
		const saveData = () => { 
            if (changedData) {
                props.setShouldSave(true);
				setChangedData(false);
            }
		}
		timerIdRef.current = setInterval(saveData, 3 * 1000); //save each 3 sec
		return () => {clearInterval(timerIdRef.current);};
    }, [changedData, props]);

    useEffect(() => {
		if (!props.isRunningEditQuestions) return;
		props.setShowGeneral(false);
		props.setIsRunningEditQuestions(false);
    }, [props]);

	const handleChangeName = (value) => { 
        if (value.length > MAX_NAME_LENGTH) return;
		props.setTestTitle(value);
		setChangedData(true);
	};

	const handleChangeDescription = (value) => { 
		if (value.length > MAX_DESC_LENGTH) return;
		props.setDescription(value);
		setChangedData(true);
	};

	const handleChangeTimeDur = (value) => { 
		props.setTimeLimitInMinutes(Number(value));
		setChangedData(true);
	};
	
	const handleTimeLimitEnabled = (checked) => { 
		props.setTimeLimitEnabled(checked);
		setChangedData(true);
	};

	const handleCompletionTypeChange = (value) => {
		props.setCompletionType(value);
		setChangedData(true);
	};

	const handleQuestionType = (event) => {
		const selInd = event.target.id;
		const checked = event.target.checked;
		if (!checked && props.questionList.filter(item => item.questionType === selInd).length > 0) {
			toast.warn('Этот тип вопросов используется и не может быть исключен.');
			return;
		}

		const list = props.questionTypeList.map((item, ind) => ({
				name: item.name, 
				id: item.id, 
				value: ind.toString() !== selInd ? item.value : checked
		}));

		props.setQuestionTypeList(list);
		setChangedData(true);
	};

	const handleIsRandomOrder = (value) => { 
		props.setIsRandomOrder(value === 'yes');
		setChangedData(true);
	};

	const handleIsAutoEstimateType = (value) => { 
		props.setIsAutoEstimateType(value === 'yes');
		setChangedData(true);
	};

	const handleTestQuestions = () => {
		if (!props.testTitle.trim() || !props.description.trim()) {
			toast.warn('Заполните поля Название и Описание и затем перейдите ко добавлению вопросов.');
		} else if (props.questionTypeList.filter(item => item.value).length === 0) {
			toast.warn('Выберите типы вопросов.');
		} else {
			props.setShowGeneral(false);
		}
	};

	const getEstimateTypeOpts = () => {
		const list = [];
		list.push({label: 'Самостоятельное оценивание учителем', value: 'no'});
		list.push({label: 'По количеству правильных ответов', value: 'yes'});
		return list;
	};

    return (
		<>
			<div className="cor-net">
				<div className={"cor-net__section"}>
					<div className="cor-net__title">Общие данные</div>
					<div className={"cor-net__row " + isDisabled(props.isEditable)}>
						<div className="cor-net__col col-1">
							<div className="cor-net__label">Название</div>
							<InputLimit
                            	value={props.testTitle} 
                            	placeholder={'Введите название онлайн теста'}
                            	onInput={e => handleChangeName(e.target.value)} 
                            	max={MAX_NAME_LENGTH}
				    		/>

						</div>
					</div>
					<div className={"cor-net__row " + isDisabled(props.isEditable)}>
						<div className="cor-net__col col-1">
							<div className="cor-net__label">Описание</div>
							<div className="cor-net__length">
								<span>{props.description.length}/{MAX_DESC_LENGTH}</span>
								<textarea 
									autoComplete="off" 
									value={props.description} 
									placeholder={'Введите описание онлайн теста'}
									name="description" 
									rows="4" 
									onChange={e => handleChangeDescription(e.target.value)} 
								/>
							</div>
						</div>
					</div>
				</div>

				<div className="cor-net__section">
					<div className="cor-net__title">Временные рамки</div>
					<div className={"cor-net__row " + isDisabled(props.isEditable)}>
						<div className="cor-net__col col-8">
							<div className="cor-net__label mb_0">Ограничение по времени</div>
						</div>
						<div className="cor-net__col col-12">
							{getDropdown(getDurationList(), props.timeLimitInMinutes, handleChangeTimeDur, '', '', 'time01')}
						</div>
						<div className="cor-net__col">
							<label className="cor-net__checkbox">
								<input 
									type="checkbox" 
									checked={props.timeLimitEnabled ? "checked" : ""} 
									onChange={e => handleTimeLimitEnabled(e.target.checked)} />
								Включить
							</label>
						</div>
					</div>
					<div className={"cor-net__row " + isDisabled(props.isEditable)}>
						<div className="cor-net__col col-8">
							<div className="cor-net__label mb_0">По истечении времени</div>
						</div>
						<div className="cor-net__col col-3">
							{getDropdown(props.completionTypeList, props.completionType, handleCompletionTypeChange, '', '', 'estim01')}
						</div>
					</div>
				</div>

				<div className="cor-net__section">
					<div className="cor-net__title">Свойства вопросов</div>
					<div className={"cor-net__row " + isDisabled(props.isEditable)}>
						<div className="cor-net__col col-8">
							<div className="cor-net__label mb_0">Случайный порядок вопросов</div>
						</div>
						<div className="cor-net__col col-12">
							{getDropdown(getYesNoOpts(), props.isRandomOrder ? 'yes' : 'no', handleIsRandomOrder, '', '', 'randomim01')}
						</div>
					</div>
					<div className="cor-net__row">
						<div className="cor-net__label">Типы вопросов</div>
					</div>
					<div className={"cor-net__row " + isDisabled(props.isEditable)}>
						{props.questionTypeList.map((item, ind) => 
							<label className="cor-net__checkbox" key={'sp' + item.id}>
								<input 
									type="checkbox"
									key={item.id}
									id={item.id}
									checked={item.value ? "checked" : ""} 
									onChange={handleQuestionType} />
								{item.name}
							</label>
						)}
					</div>
				</div>

				<div className="cor-net__section">
					<div className="cor-net__title">Выставление оценок</div>
					<div className={"cor-net__row " + isDisabled(props.isEditable)}>
						<div className="cor-net__col col-8">
							<div className="cor-net__label mb_0">Тип оценивания</div>
						</div>
						<div className="cor-net__col col-4">
							{getDropdown(getEstimateTypeOpts(), props.isAutoEstimateType ? 'yes' : 'no', handleIsAutoEstimateType, '', '', 'randomim01', "top")}
						</div>
					</div>
				</div>

				<div className="cor-net__section mt_2">
					<div className="cor-net__row">
						<Button onClick={handleTestQuestions}>
							Перейти к {props.isEditable ? 'составлению' : 'просмотру'} вопросов
						</Button>

						{props.isEditable &&
						<Button onClick={() => {setShowSaveAndExitCompleteDlg(true)}} icon={true} border="true">
							<Icon name="save"/>
							Сохранить тест и выйти
						</Button>
						}
					</div>
				</div>
			</div>

			{showSaveAndExitCompleteDlg &&
			<ModalConfirmSavingDialog
				showConfirmDlg={showSaveAndExitCompleteDlg} 
				handleNo={() => {setShowSaveAndExitCompleteDlg(false)}}
				question={'Вы действительно хотите сохранить онлайн тест и выйти?'}
				handleYes={props.handleSaveAndExit}
			/>
			}
		</>
    );
}

export default OnlineTestCreateGeneral;
