import React, { useState, useEffect, useRef } from "react";
import {useSelector, useDispatch} from "react-redux";
import labsList from '../practicum/subjectList';
import {storageClass, storageOrg} from "../../redux/slices/storage";
import {fetchUsersByOrg, fetchclass} from "../../redux/slices/classsline";
import {isAuth} from "../../helpers/auth";
import ModalConfirmDialog from "../ui/ModalDialogs/ModalConfirmDialog";
import Table from "../ui/Table/Table";
import {getClassStudents, initializeTimeCounter} from "../ui/utils/gen_utils";
import {formLabWorkId, getLabWorkArraysAndNames} from "../practicum/lw_utils";
import {PRACTICUM_SECTION}  from "../template/Dashboard/DashboardProfile";
import {labWorkInfoApi} from "../../api/api";
import {FEATURES} from "../../api/config";
import {optionsUsers} from "../../redux/slices/options";
import {getTopHeader, getHeader, getExceptionCellStyle, stopActiveTest} from "./rt_utils";
import {getScoreValue} from '../ui/utils/score_utils';
import {PRACTICUM_TEST_TYPE, getPracticumTestInfo, hasPracticumTestInfo} from "../ui/utils/ServerInfo";
import { Content, ContentBody, ContentHead } from "../template/ContentParts";
import "./ReviewActiveLabWork.scss";
import "../practicum/LabWorkShow.scss";

const ReviewActiveLabWork = (props) => {
    const [subjectId, setSubjectId] = useState('');
    const [sectionId, setSectionId] = useState('');
    const [labId, setLabId] = useState('');
    const [startTime, setStartTime] = useState(undefined);
    const [currTestTime, setCurrTestTime] = useState();
    const [labWork, setLabWork] = useState(undefined);
	const [lwRunId, setLwRunId] = useState(undefined);
	const [testTableData, setTestTableData] = useState([]);
    const [showStopTestDlg, setShowStopTestDlg] = useState(false);
	const [scoreUpdated, setScoreUpdated] = useState(false);
	const [confirmAnswer, setConfirmAnswer] = useState(false);
	
	const timerIdRef = useRef(undefined);
	const labWorkId = useRef('');
	const subjectName = useRef('');
	const sectionName = useRef('');
	const labWorkIndex = useRef(undefined);
	const documentOrg = useSelector(storageOrg);
	const documentClass = useSelector(storageClass);
	const onlineUsers = useSelector(optionsUsers);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchUsersByOrg(documentOrg._id));
        dispatch(fetchclass(isAuth()._id));
    }, [dispatch, documentOrg._id]);

  	useEffect(() => {
		if (!hasPracticumTestInfo()) return;

		const lwi = getPracticumTestInfo();
		const ids = lwi.labWorkId.split('|');
		setSubjectId(ids[0]);
		setSectionId(ids[1]);
		setLabId(ids[2]);
		setLwRunId(lwi.testRunId);
		setStartTime(lwi.startTime);
	}, []);

	useEffect(() => {
		timerIdRef.current = initializeTimeCounter(1, setCurrTestTime);//refresh in 1 sec
		return () => {clearInterval(timerIdRef.current);};
    }, []);

	useEffect(() => {
		if (!subjectId || !sectionId || !labId) return;
		labWorkId.current = formLabWorkId(subjectId, sectionId, labId);

		const laboratoryList = labsList.subjectList;
		const [subj, sect, lab] = getLabWorkArraysAndNames(laboratoryList, subjectId, sectionId, labId);
		subjectName.current = subj.subjectName;
		sectionName.current = sect.sectionName;
		setLabWork(lab);
		labWorkIndex.current = sect.labList.indexOf(lab);
	}, [subjectId, sectionId, labId]);

    useEffect(() => {
		if (!labWork) return;

        const fetchData = async () => {
			const students = getClassStudents(documentClass.classUsers);
			const owners = students.map(item => item.owner).join('|');
			const [subjectId, sectionId, labId] = labWorkId.current.split('|');

			const data = await labWorkInfoApi.getClassUserLabWorkInfosByTestByDate(owners, documentClass._id, 
					subjectId, sectionId, labId, new Date(startTime))
				.then (res => res.data)
				.catch(err => console.log('err=', err));


			const onlineStudents = onlineUsers.filter(item => item.role === 0);
			const list = [];

			//include student data from server about the test:
			for (let i = 0; i < students.length; i ++) {
				const student = students[i];
				const info = data?.find(item => item.owner === student.owner);
				const pageInd = info ? info.pageInd : 0;
				let progress = info && info.isComplete ? 'Готово' : ((!info ? '0' : '' + (pageInd+1)) + ' из ' + labWork.labPages.length);
				const score = info && info.score !== null ? getScoreValue(info.score) : '';

				const item = {
					name: student.name,
					progress: progress,
					score: score,
					online: onlineStudents.find(item => item._id === student.owner) ? 'Онлайн' : 'Офлайн',
					id: student.owner,
					personPict: student.personPict
				};

				list.push(item);
			}

			setTestTableData(list);
			setScoreUpdated(false);
        }

		fetchData();
    },[labWork, onlineUsers, currTestTime, documentClass, scoreUpdated, startTime]);

	useEffect(() => {
		if (!confirmAnswer) return;
		stopActiveTest(documentClass._id);
		setShowStopTestDlg(false);
		props.history.push('/reviewtest/lw/' + lwRunId);
	},[confirmAnswer, dispatch, documentClass._id, lwRunId, props.history]);

	const handleStopWork = () => {
		setShowStopTestDlg(true);
	};

    const handleYes = () => {
		setConfirmAnswer(true);
		setShowStopTestDlg(false);
	};
	
    const specialCellStyle = (item, rowInd, colInd, row) => {
		return getExceptionCellStyle(item);
    };

	const handlePerson = (userId) => {
		props.history.push(FEATURES['profile'].to + '/' + userId + '/' + PRACTICUM_SECTION.LAB_WORKS);
	}; 
	
	const handleScoreUpdate = async (score, ownerId) => {
		const addLabWorkInfo = async (owner, room, labWorkId, labworktest, lwRunId) => {
			const [subjectId, sectionId, labId] = labWorkId.split('|');
		
			const body = {
				owner: owner,
				room: room,
				lwRunId: lwRunId,
				subjectId: subjectId,
				sectionId: sectionId,
				labId: labId,
				pageInd: labworktest.pageInd !== undefined ? labworktest.pageInd : -1,
				isComplete: labworktest.isComplete ? labworktest.isComplete : false
			};
		
			const data = await labWorkInfoApi.addLabWorkInfo(body)
				.then (res => res.data) //++1
				.catch(err => console.log('err=', err));
			return data;
		};

		const getStudentLabWorkInfoByTest = async (userId, room, labWorkId) => {
			const [subjectId, sectionId, labId] = labWorkId.split('|');
			const result = await labWorkInfoApi.getLabWorkInfoByOwnerByTest(userId, room, subjectId, sectionId, labId)
				.then (res => res)
				.catch(err => console.log('err=', err));
			return result;
		}

		const setStudentLabWorkScore = async (owner, room, _subjectId, _sectionId, _labId, score) => {
			const labWorkId = formLabWorkId(_subjectId, _sectionId, _labId);
			const resHasInfo = await getStudentLabWorkInfoByTest(owner, room, labWorkId); //does the record exist?
			let canUpdate = true;
		
			if (resHasInfo?.data?.length === 0) {
				//the record has not been created. Create a new one and then add 
				const result = await addLabWorkInfo(owner, room, labWorkId, {}); //create the record
				if (!result) {
					canUpdate = false;
				}
			}
		
			if (canUpdate) {
				const labworkScore = {
					score: score === '' ? null : Number(score),
				};
			
				const [subjectId, sectionId, labId] = labWorkId.split('|');
				const res = await labWorkInfoApi.updateLabWorkInfoScore(owner, room, subjectId, sectionId, labId, labworkScore);
				if (res.result) return true;
			}
		
			return false;
		};

		const isUpdated  = await setStudentLabWorkScore(ownerId, documentClass._id, 
			subjectId, sectionId, labId, score);
		if (isUpdated) {
			setScoreUpdated(true);
		}
	};

	const getTableHeader = () => {
		const header = [
			{column: 'Имя ученика', name: 'name', style: { width: '25%'}},
			{column: 'В сети', name: 'online', style: { width: '25%'}},
			{column: 'Прогресс выполнения', name: 'progress', style: { width: '25%'}},
			{column: 'Оценка', name: 'score', style: { width: '25%'}},
			{column: 'id', name: 'id', style: { width: '0%'}},
		];
		return header;
	};

	if (!labWork) {
		return (
			<>
				<ContentHead title="Тест для класса не установлен." /> 
				<Content background={false} />
			</>
		);
	}

    return (
		<>
			{getTopHeader(subjectName.current, handleStopWork, PRACTICUM_TEST_TYPE.LAB_WORK)}
			<Content>
				{getHeader(labWork.labName, sectionName.current, false, 0, currTestTime, startTime)}
				<ContentBody>
					<Table 
						table={{
							header: getTableHeader(), 
							data: testTableData, 
							handleTableDataUpdate: handleScoreUpdate, 
							specialCellStyle: specialCellStyle
						}}
						sort={{
							hasSorting: true, 
							initSortInd: 1
						}}
						person={{handlePerson: handlePerson}}
						canEditScore={false}
					/>	
				</ContentBody>
			</Content>

			{showStopTestDlg &&
			<ModalConfirmDialog
				showConfirmDlg={showStopTestDlg} 
				handleNo={() => setShowStopTestDlg(false)}
				question={'Вы действительно хотите завершить лабораторную работу?'}
				handleYes={handleYes}
			/>
			}
		</>					
    );
};

export default ReviewActiveLabWork;
