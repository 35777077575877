import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from "react-redux";
import Table from "../ui/Table/Table";
import {Button} from 'rlabui';
import {fullName, isAuth} from "../../helpers/auth";
import {Tabs} from '../ui';
import {onlineTestApi, onlineTestInfoApi, onlineTestRunApi, storageApi} from "../../api/api";
import {base64ToArrayBuffer} from "../ui/utils/gen_utils";
import {PRACTICUM_TEST_TYPE} from "../ui/utils/ServerInfo";
import {ONLINETEST_SLASH, OT_TAB_STATE, OT_LIST, clearAllQuestionAttachments, uploadOTFile, loadOTFiles,
	getOnlineTestById, isQuestionWithAttachments, isQuestionWithVideo} from "./ot_utils";
import {printOnlyDate} from "../../helpers/text";
import {storageClass} from "../../redux/slices/storage";
import socket from "../../socket";
import ModalConfirmDialog from "../ui/ModalDialogs/ModalConfirmDialog";
import {toast} from "react-toastify";
import ModalInitTestByTeacherDlg from "../ui/ModalDialogs/ModalInitTestByTeacherDlg";
import {Content, ContentHeader, ContentBody, ContentFooter, ContentHead} from '../template/ContentParts';
import "../practicum/TutorialSelectTab.scss";
import "./OnlineTests.scss";
import "./OnlineTestCreate.scss";

const OnlineTests = ({ history }) => {
	const [onlineTableData, setOnlineTableData] = useState([]);
	const [showConfirmDlg, setShowConfirmDlg] = useState(false);
	const [deletionConfirmed, setDeletionConfirmed] = useState(false);
	const [idBeDeleted, setIdBeDeleted] = useState(undefined);
	const [isUpdated, setIsUpdated] = useState(false);
    const [showInitialTestModal, setShowInitialTestModal] = useState(false);
    const [isOtRun, setIsOtRun] = useState(false);
    const [workId, setWorkId] = useState(undefined);
    const [confirmQuestion, setConfirmQuestion] = useState('');
    const [isTestinUse, setIsTestinUse] = useState(false);
	const [filesBeCopied, setFilesBeCopied] = useState([]);
	const [otBeCopied, setOtBeCopied] = useState(undefined);
	const [loadedFileName, setLoadedFileName] = useState('');
	const [isLockUE, setIsLockUE] = useState(false);

    const documentClass = useSelector(storageClass);
    const {lists: {files}} = useSelector(state => state);
    const dispatch = useDispatch();
	const isSA = isAuth().role === 3; //superadmin

	useEffect(() => {
        if (!isSA)
            loadOTFiles(dispatch);
    }, [dispatch, loadedFileName, isSA]);

	useEffect(() => {
        const fetchData = async () => {
			const res = await onlineTestApi.getOnlineTests(documentClass._id);
			const otIds = res.filter(item => item.owner).map(item => item._id).join('|');
			const otRuns = await onlineTestRunApi.getOnlineTestRunsByOtIds(otIds);

			const tableList = res.map(item => ({
				titleLink: item.title, 
				timeLimitEnabled: item.timeLimitEnabled ? item.timeLimitInMinutes + ' минут' : 'Нет',
				isRandomOrder: item.isRandomOrder ? 'Да' : 'Нет',
				author: fullName(item.owner, 'Шаблон'),
				date: printOnlyDate(item.updatedAt),
				questionNumber: item.questions.length + ' шт',
				runNumber: getOtRunsNumber(item.owner, item._id, otRuns),
				actions: '',
				id: item._id 
			}));

			setOnlineTableData(tableList);
			setIsUpdated(false);
        }

		fetchData();
	},[documentClass._id, isUpdated]);

	const getOtRunsNumber = (testOwner, otId, otRuns) => {
		if (!testOwner) return 0;
		const list = otRuns.data.filter(item => item.onlineTest === otId);
		return list.length;
	};

	const deleteOnlineTestAndAllDataByOTId = async (otId, _isTestinUse, _dispatch, setIsUpdated) => {
		//1 - delete assigned files
		const data = await getOnlineTestById(otId);
		if (data.questions.length > 0 && isAuth().role < 3) {
			for (let i = 0; i < data.questions.length; i ++) {
				clearAllQuestionAttachments(data.questions[i], otId, _dispatch);
			}
		}
	
		//2 - delete online test info
		if (_isTestinUse) await onlineTestInfoApi.deleteAllOnlineTestInfosByOtId(otId)
		//3 - delete all test runs (by online test id)
		await onlineTestRunApi.deleteOnlineTestRunsByOtId(otId);
		//4 - delete the test
		await onlineTestApi.deleteOnlineTestById(otId);
		setIsUpdated(true);
	};
	useEffect(() => {
		if (!deletionConfirmed) return;
		setShowConfirmDlg(false);
		deleteOnlineTestAndAllDataByOTId(idBeDeleted, isTestinUse, dispatch, setIsUpdated);

		setDeletionConfirmed(false);
		setIdBeDeleted(undefined);
		setIsTestinUse(false);
	}, [deletionConfirmed, idBeDeleted, isTestinUse, dispatch]);

    const handleRunTest = (_isOtRun, id) => {
        const hasQuestionsInTest = async () => {
			onlineTestApi.getOnlineTestById(id)
        	.then (result => {
				if (result.questions.length > 0) {
					setIsOtRun(_isOtRun); //true = run test, false = preview test
					setShowInitialTestModal(true);
					setWorkId(id);
					if (_isOtRun) {
						socket.emit('BE-refresh-db', {type:'OnlineTestInfo', roomId: documentClass._id});
					}
				} else 
					toast.warn("Выбранный вами онлайн тест не содержит ни одного вопроса. Добавьте по крайней мере один вопрос и затем запустите тест.");
			});
        };

		hasQuestionsInTest();
    };

	const suff = () => isAuth().role < 3 ? '' : '/ot';
    const handleNewTest = () => {
        history.push(suff() + '/ot_create/0');
    };
	
	const handleEditTest = id => {
        history.push(suff()  +'/ot_create/' + id);
	};
	
    const canEditIDs = () => onlineTableData.filter(item => item.author !== 'Шаблон').map(item => item.id);

	const handleCopyTest = async (id) => {
		const ot = await getOnlineTestById(id);
		ot.title = "Копия - " + ot.title;
		const isTemplate = !ot.owner;

		if (isTemplate && isAuth().role === 3) {
			copyTemplate(ot);
		} else if (!isTemplate) {
			copyOnlineTest(ot);
		} else { //copy template to online test
			copyTemplateToOnlineTest(ot);
		}
	};

	const copyOnlineTest = async (ot) => {
		//copy attachments:
		for (let i = 0; i < ot.questions.length; i ++) {
			const question = ot.questions[i];
			if (question.illustration) {
				const illustration = JSON.parse(question.illustration);
				const name = ONLINETEST_SLASH + illustration.name;
				const {newKey} = await storageApi.copy(name, name);
				question.illustration = JSON.stringify({name: newKey.split('/').pop(), illFileType: illustration.illFileType});
			}

			if (isQuestionWithAttachments(question)) {
				if (!isQuestionWithVideo(question)) {
					const fileNames = question.answerOptions;
					for (let k = 0; k < fileNames.length; k ++) {
						const name = ONLINETEST_SLASH + fileNames[k];
						const {newKey} = await storageApi.copy(name, name);
						question.answerOptions[k] = newKey.split('/').pop();
					}
				} else { //video: special case: fileName is stored in question.correctOption
					const correctOption = question.correctOptions;
					const [answer, fileName] = correctOption.split('|');
					const name = ONLINETEST_SLASH + fileName;
					const {newKey} = await storageApi.copy(name, name);
					question.correctOptions = answer + '|' + newKey.split('/').pop();
				}
			}
		}

		addOnlineTest(ot);
	};
	
	const copyTemplate = (ot) => {
		addOnlineTest(ot);
	};
	
	const addOnlineTest = async (ot) => {
		await onlineTestApi.addOnlineTest(ot).then((res) => {
		  toast.success("Онлайн тест '" + res.title + "' сохранен.");
		  setIsUpdated(true);
		}).catch((err) => {
			toast.error(err.response.data.errors);
		});
	};

	//копирование templates в онлайн тест (3 шага)
  	//step 1 of 3: готовим информацию о файлах в шаблоне
  	const copyTemplateToOnlineTest = async (ot) => {
    	ot.room = documentClass._id;
		//готовим список с информацией о файлах, которые дорлжны быть помещены в хранилище 
		//на основе данных об этих файлах в шаблоне
		//1 - шаблон содержит инфу об items и composition - 
		//    a) items.attachmentName = [{name: f_name, content: content}], 
		//    b) composition.attachmentName = {name: f_name, content: content}
		//2 - внутренняя инфа для передачи в компоненте 
		//  a) для items - {type: 'item', ind: ind, attachmentName: item.attachmentName}
		//  b) для composition - {type: 'composion', attachmentName: scenario.composition.attachmentName}
		//3 - в итоге должны создать соответствующие файлы и поместить имена attachmentName - 
		//  items.attachmentName = f_name, composition.attachmentName = f_name

		//получим массив с сохраненными индексами
		const allQuestions = ot.questions.map((item, qInd) => ({...item, qInd: qInd}));

		//1-non-video files:
		let questions = allQuestions.filter(question => isQuestionWithAttachments(question) && 
			!isQuestionWithVideo(question));
		const arr = [];
		for (let i = 0; i < questions.length; i ++) {
			for (let k = 0; k < questions[i].answerOptions.length; k ++) 
				arr.push({type: 'file', qInd: questions[i].qInd, aoInd: k});
		}

		//2-video files:
		questions = allQuestions.filter(question => isQuestionWithVideo(question));
		for (let i = 0; i < questions.length; i ++) 
			arr.push({type: 'videofile', qInd: questions[i].qInd});

		//3-illustration:
		const illustrations = allQuestions.filter(question => question.illustration);

		for (let i = 0; i < illustrations.length; i ++) {
			arr.push({type: 'illustration', qInd: illustrations[i].qInd});
		}

		setFilesBeCopied(arr);
		setOtBeCopied(ot);
  	};

  	//step 2 of 3: готовим очередной файл и посылаем запрос на загрузку ИЛИ сохраняем сценарий
  	useEffect(() => {
		//templates contain the images inside. When copying the templates it's necessary to create 
		//image file, save them and add references to the files as attachmentName:
		if (!otBeCopied || loadedFileName || isLockUE) return;
		setIsLockUE(true);

		if (filesBeCopied.length > 0) {
			//попадаем сюда. если список файлов еще не пуст. создаем соответствующий файл и затем удаляем ссылку на него из списка
			const f = filesBeCopied[0];
			const question = otBeCopied.questions[f.qInd];
			//console.log('2/3')			
			
			const fileOpts = JSON.parse(f.type === 'file' ? question.answerOptions[f.aoInd] : 
				f.type === 'videofile' ? question.correctOptions.split('|')[1] : question.illustration);

			const fileData = fileOpts.content.split(';base64,');
			const type = fileData[0].split(':')[1]; //keep type like 'image/jpeg'
			const content = fileData[1];

			const file = new File(
				[base64ToArrayBuffer(content)],
				fileOpts.name,
				{type: type}
			);

			uploadOTFile(files, file, setLoadedFileName);
		} else {
			//попадаем сюда, когда все файлы созданы. осталось создать сам тест
			addOnlineTest(otBeCopied);
			setOtBeCopied(undefined);
			setIsLockUE(false);
		}
	}, [files, filesBeCopied, isLockUE, loadedFileName, otBeCopied]);

  //step 3 of 3: сохраняем инфу об очередном загруженном файле в данные сценария (в attachmentName), 
  //уменьшаем список filesBeCopied. Затем снова возвращаемся в шаг 2.
  	useEffect(() => {
		if (!loadedFileName || filesBeCopied.length === 0) return;
		//console.log('3/3')
		const _ot = {...otBeCopied};
		const _filesBeCopied = [...filesBeCopied];
		const f = _filesBeCopied[0];

		if (f.type === 'file') {
			_ot.questions[f.qInd].answerOptions[f.aoInd] = loadedFileName;
		} else if (f.type === 'videofile') {
			_ot.questions[f.qInd].correctOptions = 
				_ot.questions[f.qInd].correctOptions.split('|')[0] + '|' + loadedFileName;
		} else {
			const elem = JSON.parse(_ot.questions[f.qInd].illustration);
			const ill = {name: loadedFileName, illFileType: elem.illFileType};
			_ot.questions[f.qInd].illustration = JSON.stringify(ill);
		}
	
		_filesBeCopied.shift(); //удаляем инфу о созданном файле из списка
		setFilesBeCopied(_filesBeCopied); //сохраняем обновленный список
		setOtBeCopied(_ot);
		setLoadedFileName('');
		setIsLockUE(false);
	}, [loadedFileName, filesBeCopied, otBeCopied]);

	const handleRequestDeleteTest = id => {
		onlineTestInfoApi.getAllOnlineTestInfosByTest(id)
		.then (result => {
			const question = result.data.length > 0 ?
				'Удаление этого теста приведет к удалению ранее проведенных тестирований учеников и сотрет их оценки из базы. Не рекомендуется удалять.' : 
				'Вы действительно хотите удалить этот онлайн тест?';
			if (result.data.length > 0) setIsTestinUse(true);
			setIdBeDeleted(id);
			setConfirmQuestion(question);
			setShowConfirmDlg(true);
		});
	};
	
	const handleDeleteTestNo = id => {
		setShowConfirmDlg(false);
		setDeletionConfirmed(false);
		setIdBeDeleted(undefined);
	};

	const handleDeleteTestYes = () => {
		setShowConfirmDlg(false);
		setDeletionConfirmed(true);
	};
	
    const handleLink = id => {
        handleEditTest(id);
    };

	const getTableHeader = () => {
		const header = [
			{column: 'Название', name: 'titleLink', style: { width: '19%'} }, 
			{column: 'Ограничение по времени', name: 'timeLimitEnabled', style: { width: '16%'} }, 
			{column: 'Случайный порядок вопросов', name: 'isRandomOrder', style: { width: '10%'} }, 
			{column: 'Автор', name: 'author', style: { width: '10%'} }, 
			{column: 'Дата создания', name: 'date', style: { width: '15%'} }, 
			{column: 'Кол-во вопросов', name: 'questionNumber', style: { width: '10%'} }, 
			{column: 'Кол-во запусков', name: 'runNumber', style: { width: '10%'} }, 
			{column: 'Действия', name: 'actions', style: { width: '10%'} }, 
			{column: 'id', name: 'id', style: { width: '0%'} } 
		];
		return header;
	};

	const getActions = () => {
		const actions={
			handleEdit: id => handleEditTest(id),
			canEditIDs: canEditIDs(),
			handleCopy: id => handleCopyTest(id),
			handleDelete: id => handleRequestDeleteTest(id),
			canDeleteIDs: canEditIDs(),
		};

		if (isAuth().role < 3) {
			actions.handleRun = id => handleRunTest(true, id);
			actions.canRunIDs = canEditIDs();
			actions.tooltipRun = 'Начать онлайн тест';

			actions.handlePreview = id => handleRunTest(false, id);
			actions.tooltipPreview = 'Предпросмотр онлайн теста';
		}

		return actions;
	};

    const getTable = () => {
		return (
			<Table
				table={{
					header: getTableHeader(), 
					data: onlineTableData,
				}}
				link={{
					handleLink: id => handleLink(id),
				}}
				sort={{
					hasSorting: true,
					initSortInd: -5, //desc by date
				}}
				actions={getActions()}
			/>
		);
    };

    return (
		<>
			<ContentHead title="Онлайн тесты" />

			<Content>
				<ContentHeader>
					{isAuth().role < 3 &&
					<Tabs>
						{OT_TAB_STATE.map(tab => (
							<Tabs.Item 
								active={tab.status === OT_LIST}
								onClick={() => history.push(tab.path)}
								key={tab.status}
							>
								{tab.name}
							</Tabs.Item>
						))}
					</Tabs>
					}
				</ContentHeader>

				<ContentBody>
					{getTable()}
				</ContentBody>
				
				<ContentFooter className="jc-end">
					<Button onClick={handleNewTest}>Создать новый тест</Button>
				</ContentFooter>
			</Content>

			{showConfirmDlg && 
			<ModalConfirmDialog
				showConfirmDlg={showConfirmDlg} 
				handleNo={handleDeleteTestNo}
				handleYes={handleDeleteTestYes}
				question={confirmQuestion}
				btnTextYes={'Точно удалить'}
				btnTextNo={'Оставить'}
				redWarning={true}
			/>
			}
			{showInitialTestModal &&
			<ModalInitTestByTeacherDlg
				showModal={showInitialTestModal} 
				setShowModal={setShowInitialTestModal} 
				isOtRun={isOtRun}
				workId={workId}
				testType={PRACTICUM_TEST_TYPE.ONLINE_TEST}
				history={history}
			/>
			}
		</>
    )
}

export default OnlineTests;
