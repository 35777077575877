import React from "react";
import Footer from '../Footer';
import OnlineTestExecute from '../../OnlineTest/OnlineTestExecute';
import "./Dashboard.scss";
import "../../practicum/TutorialSelectTab.scss";

export const DashboardTestView = (props) => { //for testing online tests by teacher
  return (
      <div className="cor_dashboard">
        <div className="cor_dashboard__content">
          <div className="cor_dashboardBox">
            <OnlineTestExecute checkOnlineTestId={props.match?.params.id} {...props} />
          </div>
        </div>
        <Footer {...props}/>
      </div>);
}

export default DashboardTestView;
