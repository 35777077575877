import React, {useState} from 'react';
import {connect, useSelector} from "react-redux";
import {mapRlabDispatchToProps, mapStateToProps} from "./redux";
import {Icon} from "../../ui";
import {storageOptions} from "../../../redux/slices/storage";
import {printUserRole, printUserRoleGen} from "../../../helpers/text";
import {isAuth} from "../../../helpers/auth";
import packageJson from '../../../rlab/package.json';
import CorVersion from "../Header/CorVersion";
import {newsApi} from "../../../api/api";
import {FEATURES} from "../../../api/config";
import {toast} from "react-toastify";

const VERSION = packageJson.version;
const SCENARIO_ENABLED = true;

const RlabNav = (props) => {
    const options = useSelector(storageOptions);
    const {
        port,
        connectionType,
        played,
        view,
        setSensorTableSorting,
        setChartView,
        markers,
        setChartMarkers,
        tableSorting,
        setChartSettingsModal,
        setTheme,
        setSensorsModal,
        setGraduationModal,
        checkOscilloscope,
        isOscilloscope,
        setWriteStatus,
        writeStatus,
        sendCommand,
        isReading,
        setSensorReading,
        setFileExtension,
        setFile,
        setPrintModal,
        btConnect,
        connectDisconnect,
        toggleTimerModal,
        setSensorData,
        setSensorWritedList,
        setSensorWritedData,
        setSensorImported,
        showModalInfo,
        setDemoModal,
        history,
    } = props;

    const [modal, toggleModal] = useState(false);
    const [versionList, setVersionList] = useState([]);

    const clearData = () => {
        if (!played) {
            setSensorData([]);
            setSensorWritedList([]);
            setSensorWritedData([]);
            setSensorImported([]);
            for (const num in markers) {
                setChartMarkers({num, data: {}});
            }
        }
    };

    const connectToDemo = () => {
        if (connectionType) {
            toast.info('Демо-режим возможен только при отключенных датчиках');
        } else {
            setDemoModal(true);
        }
    };



    return (
        <>
            <div className="cor_footerNav__item">
                <div className="cor_footerNav__label">Файл</div>
                <div className="cor_footerNav__btns">
                    <div className="cor_footerNav__btn cor_footerNav__btn_drop">
                        <Icon name="file"/>
                        <div className="cor_footerNav__drop">
                            <ul>
                                <li>
                                    Импорт
                                    <div className="cor_footerNav__sub">
                                        <ul>
                                            <li>
                                                <label>
                                                    Exel
                                                    <input
                                                      type="file"
                                                      accept=".xls,.xlsx"
                                                      onChange={(e) => setFile(e.target.files[0])}
                                                    />
                                                </label>
                                            </li>
                                            <li>
                                                <label>
                                                    CSV
                                                    <input
                                                      type="file"
                                                      accept=".csv"
                                                      onChange={(e) => setFile(e.target.files[0])}
                                                    />
                                                </label>
                                            </li>
                                            <li
                                              onClick={() => {
                                                  if (writeStatus) {
                                                      sendCommand('wlg 0');
                                                  } else {
                                                      sendCommand('wlg 1');
                                                  }
                                                  setWriteStatus(!writeStatus);
                                              }}
                                            >
                                                {writeStatus ? 'Стоп' : 'Запись'}
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    Экспорт
                                    <div className="cor_footerNav__sub">
                                        <ul>
                                            <li onClick={() => setFileExtension('xlsx')}>Exel</li>
                                            <li onClick={() => setFileExtension('csv')}>CSV</li>
                                            <li
                                              onClick={() => {
                                                  sendCommand('rlg ' + (isReading ? 0 : 1));
                                                  setSensorReading(!isReading);
                                              }}
                                            >
                                                Лог
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li onClick={connectToDemo}>Демо режим</li>
                                <li onClick={() => setPrintModal(true)}>Печать</li>
                                <li>
                                    Помощь
                                    <div className="cor_footerNav__sub">
                                        <ul>
                                            <li>
                                                <a download rel="noreferrer"
                                                   href={"/cor_" + (printUserRole(isAuth().role).toLowerCase()) + ".docx"}>
                                                    Скачать Инструкцию {printUserRoleGen(isAuth().role)}
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li onClick={() =>
                                  newsApi.getByType(1).then((vl) => {
                                      setVersionList(vl);
                                      toggleModal(true);
                                  })}>
                                    Версия программы
                                </li>
                                <li onClick={() => {
                                    window.version = VERSION;
                                    showModalInfo(true);
                                }}>О программе
                                </li>
                            </ul>
                        </div>
                        {modal && <CorVersion modal={modal} toggleModal={toggleModal} versionList={versionList}/>}
                    </div>
                </div>
            </div>
            <div className="cor_footerNav__item">
                <div className="cor_footerNav__label">Устройства</div>
                <div className="cor_footerNav__btns">
                    <div className="cor_footerNav__btn cor_footerNav__btn_drop">
                        <Icon className="icon_smartphone"/>
                        <div className="cor_footerNav__drop">
                            <ul>
                                <li>
                                    Источник сигнала
                                    <div className="cor_footerNav__sub">
                                        <ul>
                                            <li>
                                                <span onClick={() => btConnect()}>Bluetooth</span>
                                            </li>
                                            <li onClick={() => connectDisconnect()}>USB</li>
                                        </ul>
                                    </div>
                                </li>
                                <li onClick={() => setSensorsModal(true)}>Установить каналы</li>
                                <li onClick={() => setGraduationModal(true)}>Градуировка датчика</li>
                                <li onClick={() => toggleTimerModal(true)}>Таймер</li>
                                <li>
                                    <label className="checkbox">
                                        <input
                                            checked={isOscilloscope}
                                            disabled={!isOscilloscope}
                                            onChange={() => checkOscilloscope(!isOscilloscope)}
                                            type="checkbox"
                                        />
                                        <span>Режим осциллографа</span>
                                    </label>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cor_footerNav__item">
                <div className="cor_footerNav__label">Вид</div>
                <div className="cor_footerNav__btns">
                    <div className="cor_footerNav__btn cor_footerNav__btn_drop">
                        <Icon name="grid"/>
                        <div className="cor_footerNav__drop">
                            <ul>
                                <li>
                                    Оформление окна
                                    <div className="cor_footerNav__sub">
                                        <ul>
                                            <li>
                                                <label
                                                    className="checkbox"
                                                    onClick={() => setTheme('light')}
                                                >
                                                    <input
                                                        readOnly
                                                        checked={options.theme !== 'dark'}
                                                        type="radio"
                                                        name="theme-color"
                                                    />
                                                    <span>Светлая тема</span>
                                                </label>
                                            </li>
                                            <li>
                                                <label
                                                    className="checkbox"
                                                    onClick={() => setTheme('dark')}
                                                >
                                                    <input
                                                        readOnly
                                                        checked={options.theme === 'dark'}
                                                        type="radio"
                                                        name="theme-color"
                                                    />
                                                    <span>Темная тема</span>
                                                </label>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    Отображение графиков
                                    <div className="cor_footerNav__sub">
                                        <ul>
                                            <li>
                                                <label className="checkbox">
                                                    <input
                                                        type="checkbox"
                                                        value="one"
                                                        checked={view === 'one'}
                                                        onChange={() => setChartView('one')}
                                                        readOnly
                                                    />
                                                    <span>1 график</span>
                                                </label>
                                            </li>
                                            <li>
                                                <label className="checkbox">
                                                    <input
                                                        type="checkbox"
                                                        value="two"
                                                        checked={view === 'two'}
                                                        onChange={() => {
                                                            setChartView('two');
                                                            if (view === 'three') {
                                                                setChartView('one');
                                                                setTimeout(function () {
                                                                    setChartView('two');
                                                                }, 1);
                                                            } else {
                                                                setChartView('two');
                                                            }
                                                        }}
                                                        readOnly
                                                    />
                                                    <span>2 графика</span>
                                                </label>
                                            </li>
                                            <li>
                                                <label className="checkbox">
                                                    <input
                                                        type="checkbox"
                                                        value="three"
                                                        checked={view === 'three'}
                                                        onChange={() => setChartView('three')}
                                                        readOnly
                                                    />
                                                    <span>3 графика</span>
                                                </label>
                                            </li>
                                            <li>
                                                <label className="checkbox">
                                                    <input
                                                        type="checkbox"
                                                        value="four"
                                                        checked={view === 'four'}
                                                        onChange={() => setChartView('four')}
                                                        readOnly
                                                    />
                                                    <span>4 графика</span>
                                                </label>
                                            </li>
                                            <li>
                                                <label className="checkbox">
                                                    <input
                                                        type="checkbox"
                                                        value="multy"
                                                        checked={view === 'multy'}
                                                        onChange={() => {
                                                            if (view === 'mono') {
                                                                setChartView('one');
                                                                setTimeout(function () {
                                                                    setChartView('multy');
                                                                }, 1);
                                                            } else {
                                                                setChartView('multy');
                                                            }
                                                        }}
                                                        readOnly
                                                    />
                                                    <span>Мульти-ось</span>
                                                </label>
                                            </li>
                                            <li>
                                                <label className="checkbox">
                                                    <input
                                                        type="checkbox"
                                                        value="mono"
                                                        checked={view === 'mono'}
                                                        onChange={() => {
                                                            if (view === 'multy') {
                                                                setChartView('one');
                                                                setTimeout(function () {
                                                                    setChartView('mono');
                                                                }, 1);
                                                            } else {
                                                                setChartView('mono');
                                                            }
                                                        }}
                                                        readOnly
                                                    />
                                                    <span>Моно-ось</span>
                                                </label>
                                            </li>
                                            <li onClick={() => setChartView('two')}>Очистить</li>
                                        </ul>
                                    </div>
                                </li>
                                <li onClick={() => port && setChartSettingsModal(true)}>Стили графиков</li>
                                <li>
                                    Сортировка таблиц
                                    <div className="cor_footerNav__sub">
                                        <ul>
                                            <li>
                                                <label className="checkbox">
                                                    <input
                                                        type="radio"
                                                        name="alfa-sorts"
                                                        checked={!tableSorting}
                                                        onChange={(e) => e.target.checked && setSensorTableSorting(false)}
                                                    />
                                                    <span>По возрастанию а-я</span>
                                                </label>
                                            </li>
                                            <li>
                                                <label className="checkbox">
                                                    <input
                                                        type="radio"
                                                        name="alfa-sorts"
                                                        checked={tableSorting}
                                                        onChange={(e) => e.target.checked && setSensorTableSorting(true)}
                                                    />
                                                    <span>По убыванию я-а</span>
                                                </label>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li onClick={clearData}>Очистить</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {SCENARIO_ENABLED && isAuth().role === 1 &&
            <div className="cor_footerNav__item">
                <div className="cor_footerNav__label">{FEATURES.rlsc.title}</div>
                <div className="cor_footerNav__btn" onClick={() => history.push('/rlsc/opt')}>
                    <Icon name="script" />
                </div>
            </div>
            }
        </>
    );
};

export default  connect(mapStateToProps, mapRlabDispatchToProps)(RlabNav);
