import axios from "axios";
import cookie from "js-cookie";

const apiServer = process.env.REACT_APP_API_URL.split('/')[2].split(':')[0].split('.')[0];

const headers = cookie.get("cor") ? {Authorization: 'Bearer ' + cookie.get("cor")} : {};
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL + "/api",
  responseType: "json",
  headers,
});

const vapi = axios.create({
  baseURL: process.env.REACT_APP_VIDEO_URL + "/api",
  responseType: "json",
  headers,
});

const getVapi = (options)=>{
  if (options && options.videoUrl && options.videoKey) {
    return axios.create({
      baseURL: options.videoUrl + "/api",
      responseType: "json",
      headers: {Authorization: 'Bearer ' + options.videoKey},
    });
  } else {
    return vapi;
  }
}

export const apiSetToken =  (v) => {
  api.defaults.headers.Authorization = 'Bearer ' + v;
  vapi.defaults.headers.Authorization = 'Bearer ' + v;
};

export const authApi = {
  async login(user) {
    const {data} = await api.post(`auth/login`, user);
    return data;
  },
}

export const classApi = {
  async getUsersByOrg(id) {
    const { data } = await api.get(`class/usersbyorg/`+ id);
    return data;
  },
  async getOrgsAll() {
    const { data } = await api.get(`class/orgsall/`);
    return data;
  },
  async getclassByOrg(iduser) {
    const { data } = await api.get(`class/byorg/${iduser}`);
    return data;
  },
  async getclassById(idclass) {
    const { data } = await api.get(`class/` + idclass);
    return data;
  },
  async deleteClass(id) {
    const { data } = await api.delete(`class/${id}`);
    return data;
  },
};

export const AddclassApi = {
  async addClass(cl) {
    const { data } = await api.post(`class/`, cl);
    return data;
  },
  async updateClass(id,cl) {
    const { data } = await api.put(`class/${id}`, cl);
    return data;
  },
  async addUserToClass(idclass,uid) {
    const { data } = await api.put(`class/${idclass}/${uid}`);
    return data;
  },
  async removeUserFromClass(idclass,uid) {
    const { data } = await api.put(`class/r/${idclass}/${uid}`);
    return data;
  },
};

export const userApi = {
  async register(user) {
    const { data } = await api.post(`user/`, user);
    return data;
  },
  async get(id) {
    const { data } = await api.get(`user/${id}`);
    return data;
  },
  async update(id, user) {
    const { data } = await api.put(`user/${id}`, user);
    return data;
  },
  async password(user) {
    const { data } = await api.post(`user/password`, user);
    return data;
  },
  async delete(id) {
    const { data } = await api.delete(`user/${id}`);
    return data;
  },
};

export const chatApi = {
  async getChat(room, count) {
    const { data } = await api.get(`chat/${room}/${count}`);
    return data;
  },
  async getNotification(room, count) {
    const { data } = await api.get(`notification/${room}/${count}`);
    return data;
  },
};

export const labWorkRunApi = {
  async addLabWorkRun(labworkTestData) {
    const {data} = await api.post(`practicum/addLabWorkRun/`, labworkTestData);
    return data;
  },
  async getLabWorkRunByClassOwners(owners, room) {
    const {data} = await api.get(`practicum/getLabWorkRunByClassOwners/${owners}/${room}`);
    return data;
  },
  async getLabWorkRunById(id) {
    const {data} = await api.get(`practicum/getLabWorkRunById/${id}`);
    return data;
  },
  async deleteLabWorkRunById(id) {
    const {data} = await api.delete(`practicum/deleteLabWorkRunById/${id}`);
    return data;
  },
};

export const labWorkInfoApi = {
  async addLabWorkInfo(lwInfo) {
    const {data} = await api.post(`practicum/addLabWorkInfo/`, lwInfo);
    return data;
  },
  async getLabWorkInfoById(id) {
    const {data} = await api.get(`practicum/getLabWorkInfoById/${id}`);
    return data;
  },
  async initClassUsersLabWorkInfo(lwInfos) {
    const {data} = await api.post(`practicum/initClassUsersLabWorkInfo/`, lwInfos);
    return data;
  },
  async getLabWorkInfoByRunId(owner, room, runId) {
    const {data} = await api.get(`practicum/getLabWorkInfoByRunId/${owner}/${room}/${runId}`);
    return data;
  },
  async getClassUserLabWorkInfosByRunId(runId) {
    const {data} = await api.get(`practicum/getClassUserLabWorkInfosByRunId/${runId}`);
    return data;
  },
  async getLabWorkInfoByOwnerByTest(owner, room, subjectId, sectionId, labId) {
    const {data} = await api.get(`practicum/getLabWorkInfoByOwnerByTest/${owner}/${room}/${subjectId}/${sectionId}/${labId}`);
    return data;
  },
  async getClassUserLabWorkInfosByTestByDate(owners, room, subjectId, sectionId, labId, date) {
    const {data} = await api.get(`practicum/getClassUserLabWorkInfosByTestByDate/${owners}/${room}/${subjectId}/${sectionId}/${labId}/${date}`);
    return data;
  },
  async getLabWorkInfosByOwner(owner, room) {
    const {data} = await api.get(`practicum/getLabWorkInfosByOwner/${owner}/${room}`);
    return data;
  },
  async deleteLabWorkInfosByRunId(runId) {
    const {data} = await api.delete(`practicum/deleteLabWorkInfosByRunId/${runId}`);
    return data;
  },
  async updateLabWorkInfoById(id, lwInfo) {
    const {data} = await api.put(`practicum/updateLabWorkInfoById/${id}`, lwInfo);
    return data;
  },
  async updateLabWorkInfoScore(owner, room, subjectId, sectionId, labId, score) {
    const {data} = await api.put(`practicum/updateLabWorkInfoScore/${owner}/${room}/${subjectId}/${sectionId}/${labId}`, score);
    return data;
  },
  async updateLabWorkInfoScoreById(id, score) {
    const {data} = await api.put(`practicum/updateLabWorkInfoScoreById/${id}`, score);
    return data;
  },
};

export const rlabApi = {
  async getByRoom(room, uid) {
    const { data } = await api.get(`rlab/${room}/${uid}/all`);
    return data;
  },
  async getByUser(room, uid) {
    const { data } = await api.get(`rlab/${room}/${uid}/none`);
    return data;
  },
  async getById(id) {
    const { data } = await api.get(`rlab/${id}`);
    return data;
  },
  async add(rlab) {
    const { data } = await api.post(`rlab/`, rlab);
    return data;
  },
  async update(id, rlab) {
    const { data } = await api.put(`rlab/${id}`, rlab);
    return data;
  },
  async delete(id) {
    const { data } = await api.delete(`rlab/${id}`);
    return data;
  },
};

export const activeLabWorkApi = {
  async addActiveLabWork(activelabwork) {
    const { data } = await api.post(`practicum/addActiveLabWork/`, activelabwork);
    return data;
  },
  async getActiveLabWorkByTeachers(owners, room) {
    const { data } = await api.get(`practicum/getActiveLabWorkByTeachers/${owners}/${room}`);
    return data;
  },
  async getActiveLabWorkByTeacher(owner, room) {
    const { data } = await api.get(`practicum/getActiveLabWorkByTeacher/${owner}/${room}`);
    return data;
  },
  async deleteActiveLabWork(owner, room, labWorkId) {
    const { data } = await api.delete(`practicum/deleteActiveLabWork/${owner}/${room}/${labWorkId}`);
    return data;
  },
  async deleteOrphanActiveLabWork(recordId) {
    const { data } = await api.delete(`practicum/deleteOrphanActiveLabWork/${recordId}/`);
    return data;
  },
};

export const onlineTestApi = {
  async addOnlineTest(onlineTestData) {
    const { data } = await api.post(`practicum/addOnlineTest/`, onlineTestData);
    return data;
  },
  async getOnlineTestById(id) {
    const { data } = await api.get(`practicum/getOnlineTestById/${id}`);
    return data;
  },
  async getOnlineTests(room) {
    const { data } = await api.get(`practicum/getOnlineTests/${room}`); //owner
    return data;
  },

  async updateOnlineTestById(onlineTestId, onlineTestData) {
    const { data } = await api.put(`practicum/updateOnlineTestById/${onlineTestId}`, onlineTestData);
    return data;
  },
  async deleteOnlineTestById(onlineTestId) {
    const { data } = await api.delete(`practicum/deleteOnlineTestById/${onlineTestId}/`);
    return data;
  },
  async getAllOnlineTestsByIDs(onlineTestIDs) {
    const { data } = await api.get(`practicum/getAllOnlineTestsByIDs/${onlineTestIDs}`);
    return data;
  },
  async getIsAttachmentUnique(owner, onlineTestID, fileName, isVideo) {
    const { data } = await api.get(`practicum/getIsAttachmentUnique/${owner}/${onlineTestID}/${fileName}/${isVideo}`);
    return data;
  },
};

export const onlineTestRunApi = {
  async addOnlineTestRun(onlineTestTestData) {
    const {data} = await api.post(`practicum/addOnlineTestRun/`, onlineTestTestData);
    return data;
  },
  async getOnlineTestRunsByClassOwners(owners, room) {
    const {data} = await api.get(`practicum/getOnlineTestRunsByClassOwners/${owners}/${room}`);
    return data;
  },
  async getOnlineTestRunById(id) {
    const {data} = await api.get(`practicum/getOnlineTestRunById/${id}`);
    return data;
  },
  async getOnlineTestRunsByOtIds(otIds) {
    const {data} = await api.get(`practicum/getOnlineTestRunsByOtIds/${otIds}`);
    return data;
  },
  async getOnlineTestRunsByOtId(otId) {
    const {data} = await api.get(`practicum/getOnlineTestRunsByOtId/${otId}`);
    return data;
  },
  async deleteOnlineTestRunById(onlineTestRunId) {
    const {data} = await api.delete(`practicum/deleteOnlineTestRunById/${onlineTestRunId}`);
    return data;
  },
  async deleteOnlineTestRunsByOtId(onlineTestId) {
    const {data} = await api.delete(`practicum/deleteOnlineTestRunsByOtId/${onlineTestId}`);
    return data;
  },
};

export const onlineTestInfoApi = { 
  async addOnlineTestInfo(info) { //not used
    const { data } = await api.post(`practicum/addOnlineTestInfo/`, info);
    return data;
  },
  async addAllClassUsersOnlineTestInfos(allStudentOTInfos) {
    const { data } = await api.post(`practicum/addAllClassUsersOnlineTestInfos/`, allStudentOTInfos);
    return data;
  },
  async getOnlineTestInfoByRunId(owner, otRunId) {
    const { data } = await api.get(`practicum/getOnlineTestInfoByRunId/${owner}/${otRunId}`);
    return data;
  },
  async getAllClassUserOnlineTestInfosByRunId(otRunId) {
    const { data } = await api.get(`practicum/getAllClassUserOnlineTestInfosByRunId/${otRunId}`);
    return data;
  },
  async getAllOnlineTestInfosByOwner(owner, room) {
    const { data } = await api.get(`practicum/getAllOnlineTestInfosByOwner/${owner}/${room}`);
    return data;
  },
  async getAllOnlineTestInfosByClassStudentsByTestByDate(owners, room, onlineTest, date) {
    const { data } = await api.get(`practicum/getAllOnlineTestInfosByClassStudentsByTestByDate/${owners}/${room}/${onlineTest}/${date}`);
    return data;
  },
  async getAllOnlineTestInfosByTest(onlineTest) {
    const { data } = await api.get(`practicum/getAllOnlineTestInfosByTest/${onlineTest}`);
    return data;
  },
  async getAllTeacherNonCheckedOtTasks(room) {
    const { data } = await api.get(`practicum/getAllTeacherNonCheckedOtTasks/${room}`);
    return data;
  },
  async getTeacherNonCheckedOtTasksByRunId(room, otrun) {
    const { data } = await api.get(`practicum/getTeacherNonCheckedOtTasksByRunId/${room}/${otrun}`);
    return data;
  },
  async deleteAllOnlineTestInfosByOtId(onlineTest) {
    const { data } = await api.delete(`practicum/deleteAllOnlineTestInfosByOtId/${onlineTest}`);
    return data;
  },
  async deleteAllOnlineTestInfosByRunId(otRunId) {
    const { data } = await api.delete(`practicum/deleteAllOnlineTestInfosByRunId/${otRunId}`);
    return data;
  },
  async updateOnlineTestInfoById(id, onlineTestData) {
    const { data } = await api.put(`practicum/updateOnlineTestInfoById/${id}`, onlineTestData);
    return data;
  },
  async updateOnlineTestInfoScoreById(id, onlineTestData) {
    const { data } = await api.put(`practicum/updateOnlineTestInfoScoreById/${id}`, onlineTestData);
    return data;
  },
};
//=============
export const vrLabRunApi = {//not used
  async addVrLabRun(vrLabData) {
    const {data} = await api.post(`practicum/addVrLabRun/`, vrLabData);
    return data;
  },
  async getVrLabRunByClassOwners(owners, room) {
    const {data} = await api.get(`practicum/getVrLabRunByClassOwners/${owners}/${room}`);
    return data;
  },
  async getVrLabRunDetailsByRunId(vrRunId) {
    const {data} = await api.get(`practicum/getVrLabRunDetailsByRunId/${vrRunId}`);
    return data;
  },
  async deleteVrLabRunByRunId(vrRunId) {
    const {data} = await api.delete(`practicum/deleteVrLabRunByRunId/${vrRunId}`);
    return data;
  },
};

//=============
export const vrLabInfoApi = {
  async getVrLabInfos(room) {
    const {data} = await api.get(`practicum/getVrLabInfos/${room}`);
    return data;
  },
  async getVrLabInfoById(id) {
    const {data} = await api.get(`practicum/getVrLabInfoById/${id}`);
    return data;
  },
  async addVrLabInfo(obj) {
    const { data } = await api.post(`practicum/addVrLabInfo`, obj);
    return data;
  },
  async updateVrLabInfoById(id, obj) {
    const { data } = await api.put(`practicum/updateVrLabInfoById/${id}`, obj);
    return data;
  },
  async deleteVrLabInfoById(id) {
    const { data } = await api.delete(`practicum/deleteVrLabInfoById/${id}`);
    return data;
  },
  //
  async addInitVrLabInfoByOwner(obj) {
    const { data } = await api.post(`practicum/addInitVrLabInfoByOwner`, obj);
    return data;
  },
  async getAllVrLabInfosByClassStudentsByVrLabInd(room, vrLabInd) {
    const {data} = await api.get(`practicum/getAllVrLabInfosByClassStudentsByVrLabInd/${room}/${vrLabInd}`);
    return data;
  },
  async getVrLabInfoByOwnerIdByVrLabInd(owner, vrLabInd) {
    const {data} = await api.get(`practicum/getVrLabInfoByOwnerIdByVrLabInd/${owner}/${vrLabInd}`);
    return data;
  },
  async getVrLabInfoByOwnerId(owner) {
    const {data} = await api.get(`practicum/getVrLabInfoByOwnerId/${owner}`);
    return data;
  },
  async getAllVrLabInfosByAllClassStudents(owners) {
    const {data} = await api.get(`practicum/getAllVrLabInfosByAllClassStudents/${owners}`);
    return data;
  },
  async updateVrLabInfoStateById(id, obj) {
    const { data } = await api.put(`practicum/updateVrLabInfoStateById/${id}`, obj);
    return data;
  },
  async updateVrLabInfoScoreById(id, obj) {
    const { data } = await api.put(`practicum/updateVrLabInfoScoreById/${id}`, obj);
    return data;
  },
  async deleteVrLabInfoByRoomByInd(room, vrLabInd) {
    const { data } = await api.delete(`practicum/deleteVrLabInfoByRoomByInd/${room}/${vrLabInd}`);
    return data;
  },
};

//=============
export const calcModelInfoApi = {
  async addCalcModelInfo(cmiData) {
    const { data } = await api.post(`practicum/addCalcModelInfo/`, cmiData);
    return data;
  },
  async getCalcModelInfoById(id) {
    const { data } = await api.get(`practicum/getCalcModelInfoById/${id}`);
    return data;
  },
  async getAllCalcModelInfosByOwner(owner) {
    const { data } = await api.get(`practicum/getAllCalcModelInfosByOwner/${owner}`);
    return data;
  },
  async deleteCalcModelInfoById(id) {
    const { data } = await api.delete(`practicum/deleteCalcModelInfoById/${id}`);
    return data;
  },
};

//===============
export const notebookApi = {
  async addNotebook(nbData) {
    const { data } = await api.post(`practicum/addNotebook/`, nbData);
    return data;
  },
  async getNotebooks(owner, room) {
    const { data } = await api.get(`practicum/getNotebooks/${room}`);
    return data;
  },
  async getNotebookById(id) {
    const { data } = await api.get(`practicum/getNotebookById/${id}`);
    return data;
  },
  async updateNotebookById(id, nbData) {
    const { data } = await api.put(`practicum/updateNotebookById/${id}`, nbData);
    return data;
  },
  async deleteNotebookById(id) {
    const { data } = await api.delete(`practicum/deleteNotebookById/${id}`);
    return data;
  },

  async getNotebooksByIds(ids) {
    const { data } = await api.get(`practicum/getNotebooksByIds/${ids}`);
    return data;
  },
  async updateAllNotebookStatusesBeCompletedByIds(ids) {
    const { data } = await api.put(`practicum/updateAllNotebookStatusesBeCompletedByIds/${ids}`);
    return data;
  },
};
//===
export const notebookInfoApi = {
  async addNotebookInfo(notebookInfoData) { //input contains owner
    const { data } = await api.post(`practicum/addNotebookInfo/`, notebookInfoData);
    return data;
  },
  async getNotebookInfoById(id) { //owner is student id, notebook is notebook id
    const { data } = await api.get(`practicum/getNotebookInfoById/${id}`);
    return data;
  },
  async getAllNotebookInfoByOwner(owner) { //owner is student id
    const { data } = await api.get(`practicum/getAllNotebookInfoByOwner/${owner}`);
    return data;
  },

  async getAllOwnersNotebookInfoByNotebookId(notebook) { //notebook is notebook id
    const { data } = await api.get(`practicum/getAllOwnersNotebookInfoByNotebookId/${notebook}`);
    return data;
  },
  async getAllNonCheckedNbi(room) {
    const { data } = await api.get(`practicum/getAllNonCheckedNbi/${room}`);
    return data;
  },
    
  async updateNotebookInfoScoreById(id, score) {  //id is NotebookInfo id
    const { data } = await api.put(`practicum/updateNotebookInfoScoreById/${id}`, score);
    return data;
  },
  async updateNotebookInfoUserWorkErrorLimitById(id, userWorkErrorLimit) {  //id is NotebookInfo id
    const { data } = await api.put(`practicum/updateNotebookInfoUserWorkErrorLimitById/${id}`, userWorkErrorLimit);
    return data;
  },
  async updateNotebookInfoTaskDataById(id, taskInfos) {  //id is NotebookInfo id, taskInfos is task information including answers
    const { data } = await api.put(`practicum/updateNotebookInfoTaskDataById/${id}`, taskInfos);
    return data;
  },
  async updateNotebookInfoSpentTimeById(id, actualSpentTime) {  //id is NotebookInfo id, update actualSpentTime
    const { data } = await api.put(`practicum/updateNotebookInfoSpentTimeById/${id}`, actualSpentTime);
    return data;
  },
  async updateNotebookInfoIsCompleteById(id) {  //id (info Id), no body
    const { data } = await api.put(`practicum/updateNotebookInfoIsCompleteById/${id}`);
    return data;
  },
  async deleteNotebookInfoById(notebookInfoId) { //notebookInfoId
    const { data } = await api.delete(`practicum/deleteNotebookInfoById/${notebookInfoId}`);
    return data;
  }, 
  async deleteNotebookInfoByNotebookId(notebook) { //notebook is notebook id
    const { data } = await api.delete(`practicum/deleteNotebookInfoByNotebookId/${notebook}`);
    return data;
  }, 
};

export const videoEditorApi = {
  async addVideoEditor(videoEditorData) {
    const { data } = await api.post(`practicum/addVideoEditor/`, videoEditorData);
    return data;
  },
  async getVideoEditorById(videoEditorId) {
    const { data } = await api.get(`practicum/getVideoEditorById/${videoEditorId}`);
    return data;
  },
  async getAllVideoEditorsByOwner(owner) {
    const { data } = await api.get(`practicum/getAllVideoEditorsByOwner/${owner}`);
    return data;
  },
  async updateVideoEditorById(videoEditorId, videoEditorData) {
    const { data } = await api.put(`practicum/updateVideoEditorById/${videoEditorId}`, videoEditorData);
    return data;
  },
  async deleteVideoEditorById(videoEditorId) {
    const { data } = await api.delete(`practicum/deleteVideoEditorById/${videoEditorId}/`);
    return data;
  },
};

export const deviceApi = {
  async get() {
    const {data} = await api.get(`device/`);
    return data;
  },
  async add(device) {
    const {data} = await api.post(`device/`, device);
    return data;
  },
  async addFirmware(id, file) {
    const formData = new FormData();
    formData.append('firmware', file, file.name);
    const {data} = await api.post(`device/${id}`, formData);
    return data;
  },
  async update(id, device) {
    const {data} = await api.put(`device/${id}`, device);
    return data;
  },
  async updateFirmware(id, fid, name) {
    const {data} = await api.put(`device/${id}/${fid}`, {name: name});
    return data;
  },
  async delete(id) {
    const {data} = await api.delete(`device/${id}`);
    return data;
  },
  async deleteFirmware(id, fid) {
    const {data} = await api.delete(`device/${id}/${fid}`);
    return data;
  },
  async getSource() {
    const {data} = await api.get(`source`);
    return data;
  },
  async saveSource(file) {
    const formData = new FormData();
    formData.append('source', file, 'source.xml');
    const {data} = await api.post("source", formData);
    return data;
  },
};

export const storageApi = {
  async getFiles(key) {
    const {data} = await api.get(`storage/${key}`);
    return data;
  },
  async getUrl(key) {
    const {data} = await api.get(`storage/url/${key}`);
    return data;
  },
  async getUploadUrl(key) {
    const {data} = await api.get(`storage/upload/${key}`);
    return data;
  },
  async copy(key, newKey) {
    key = encodeURIComponent(key);
    const action = {
      newKey,
      rename: false
    }
    const {data} = await api.put(`storage/${key}`, action);
    return data;
  },
  async rename(key, newKey) {
    key = encodeURIComponent(key);
    const action = {
      newKey,
      rename: true
    }
    const {data} = await api.put(`storage/${key}`, action);
    return data;
  },
  async delete(key) {
    key = encodeURIComponent(key);
    const {data} = await api.delete(`storage/${key}`);
    return data;
  },

  async getQuota(key) {
    const {data} = await api.get(`storage/quota/${key}`);
    return data;
  },
  async setQuota(key, size) {
    const {data} = await api.put(`storage/quota/${key}`, {size});
    return data;
  },
  async getBucket(key) {
    const {data} = await api.get(`storage/bucket/${key}`);
    return data;
  },
  async addBucket(key, size) {
    const {data} = await api.put(`storage/bucket/${key}`, {size});
    return data;
  },
};

export const streamApi = {
  async get(room, options) {
    const {data} = await getVapi(options).get(`stream/${apiServer}/${room}`);
    return data;
  },
  async consumer(room, payload, options={}) {
    const {data} = await getVapi(options).post(`stream/${apiServer}/${room}`, payload);
    return data;
  },
  async broadcast(room, payload, options={}) {
    const {data} = await getVapi(options).post(`stream/broadcast/${apiServer}/${room}`, payload);
    return data;
  },
};

export const trackerApi = {
  async getTasksByRoom(room, opt = 1) {
    const { data } = await api.get(`tracker/task/byroom/${room}/${opt}`);
    return data;
  },
  async getTaskById(id) {
    const { data } = await api.get(`tracker/task/${id}`);
    return data;
  },
  async addTask(task) {
    const { data } = await api.post(`tracker/task/`, task);
    return data;
  },
  async updateTask(id, task) {
    const { data } = await api.put(`tracker/task/${id}`, task);
    return data;
  },
  async deleteTask(id) {
    const { data } = await api.delete(`tracker/task/${id}`);
    return data;
  },
  async archiveTasks(ids) {
    const { data } = await api.post(`tracker/task/archive`, {ids});
    return data;
  },
  async getGroupsByRoom(room) {
    const { data } = await api.get(`tracker/group/byroom/${room}`);
    return data;
  },
  async getGroupById(id) {
    const { data } = await api.get(`tracker/group/${id}`);
    return data;
  },
  async addGroup(group) {
    const { data } = await api.post(`tracker/group/`, group);
    return data;
  },
  async updateGroup(id, group) {
    const { data } = await api.put(`tracker/group/${id}`, group);
    return data;
  },
  async sortGroup(id, field, direction) {
    const { data } = await api.put(`tracker/group/sort/${id}`, {field, direction});
    return data;
  },
  async deleteGroup(id) {
    const { data } = await api.delete(`tracker/group/${id}`);
    return data;
  },
};

export const piApi = {
  async getProperties(room) {
    const {data} = await api.get(`pi/properties/${room}`);
    return data;
  },
  async getPropertyById(id) {
    const {data} = await api.get(`pi/property/${id}`);
    return data;
  },
  async addProperty(obj) {
    const { data } = await api.post(`pi/property`, obj);
    return data;
  },
  async updateOPropertyById(id, obj) {
    const { data } = await api.put(`pi/property/${id}`, obj);
    return data;
  },
  async deletePropertyById(id) {
    const { data } = await api.delete(`pi/property/${id}`);
    return data;
  },
  async getObjects(room) {
    const {data} = await api.get(`pi/objects/${room}`);
    return data;
  },
  async getObjectById(id) {
    const {data} = await api.get(`pi/object/${id}`);
    return data;
  },
  async addObject(obj) {
    const { data } = await api.post(`pi/object`, obj);
    return data;
  },
  async updateObjectById(id, obj) {
    const { data } = await api.put(`pi/object/${id}`, obj);
    return data;
  },
  async deleteObjectById(id) {
    const { data } = await api.delete(`pi/object/${id}`);
    return data;
  },

  async getResearches(room, status) {
    const ALL = -2;
    if (status === ALL) {
      const {data} = await api.get(`pi/researchesAll/${room}`);
      return data;
    }

    const {data} = await api.get(status ? `pi/researches/${room}/${status}` : `pi/researches/${room}`);
    return data;
  },
  async getResearchById(id) {
    const {data} = await api.get(`pi/research/${id}`);
    return data;
  },
  async addResearch(research) {
    const { data } = await api.post(`pi/research`, research);
    return data;
  },
  async updateResearchById(id, research) {
    const { data } = await api.put(`pi/research/${id}`, research);
    return data;
  },
  async statusResearchById(id, status, __v) {
    const {data} = await api.put(`pi/research/status/${id}`, {status, __v});
    return data;
  },
  async deleteResearchById(id) {
    const { data } = await api.delete(`pi/research/${id}`);
    return data;
  },

  async getSystems(room) {
    const {data} = await api.get(`pi/systems/${room}`);
    return data;
  },
  async addSystem(obj) {
    const { data } = await api.post(`pi/system`, obj);
    return data;
  },
  async updateSystemById(id, obj) {
    const { data } = await api.put(`pi/system/${id}`, obj);
    return data;
  },
  async deleteSystemById(id) {
    const { data } = await api.delete(`pi/system/${id}`);
    return data;
  },

  async getQuants(room) {
    const {data} = await api.get(`pi/quants/${room}`);
    return data;
  },
  async addQuant(obj) {
    const { data } = await api.post(`pi/quant`, obj);
    return data;
  },
  async updateQuantById(id, obj) {
    const { data } = await api.put(`pi/quant/${id}`, obj);
    return data;
  },
  async deleteQuantById(id) {
    const { data } = await api.delete(`pi/quant/${id}`);
    return data;
  },

  async getUnits(room) {
    const {data} = await api.get(`pi/units/${room}`);
    return data;
  },
  async getUnitById(id) {
    const {data} = await api.get(`pi/unit/${id}`);
    return data;
  },
  async addUnit(unit) {
    const { data } = await api.post(`pi/unit`, unit);
    return data;
  },
  async updateUnitById(id, unit) {
    const { data } = await api.put(`pi/unit/${id}`, unit);
    return data;
  },
  async deleteUnitById(id) {
    const { data } = await api.delete(`pi/unit/${id}`);
    return data;
  },

  async getDependencies(room) {
    const {data} = await api.get(`pi/dependencies/${room}`);
    return data;
  },
  async addDependency(unit) {
    const { data } = await api.post(`pi/dependency`, unit);
    return data;
  },
  async updateDependencyById(id, unit) {
    const { data } = await api.put(`pi/dependency/${id}`, unit);
    return data;
  },
  async deleteDependencyById(id) {
    const { data } = await api.delete(`pi/dependency/${id}`);
    return data;
  },
}

export const newsApi = {
  async getByType(type) {
    const { data } = await api.get(`news/type/${type}`);
    return data;
  },
  async getAdminByType(type) {
    const { data } = await api.get(`news/admin/${type}`);
    return data;
  },
  async getById(id) {
    const { data } = await api.get(`news/id/${id}`);
    return data;
  },
  async add(news) {
    const { data } = await api.post(`news/`, news);
    return data;
  },
  async update(id, news) {
    const { data } = await api.put(`news/${id}`, news);
    return data;
  },
  async delete(id) {
    const { data } = await api.delete(`news/${id}`);
    return data;
  },
};

export const rlsApi = {
  async addScenario(obj) {
    const { data } = await api.post(`rls/scenario`, obj);
    return data;
  },
  async getScenarios(room) {
    const {data} = await api.get(`rls/scenarios/${room}`);
    return data;
  },
  async getScenarioById(id) {
    const {data} = await api.get(`rls/scenario/${id}`);
    return data;
  },
  async updateScenarioById(id, obj) {
    const { data } = await api.put(`rls/scenario/${id}`, obj);
    return data;
  },
  async deleteScenarioById(id) {
    const { data } = await api.delete(`rls/scenario/${id}`);
    return data;
  },
};

export const rlsiApi = {
  async addScenarioInfo(obj) {
    const { data } = await api.post(`rls/addScenarioInfo`, obj);
    return data;
  },
  async getScenarioInfos(room) { //not used yet
    const {data} = await api.get(`rls/getScenarioInfos/${room}`);
    return data;
  },
  async getScenarioInfoById(id) {
    const {data} = await api.get(`rls/getScenarioInfoById/${id}`);
    return data;
  },
  async getAllStudentsRlScenarioInfoByScenarioId(scenarioId) {
    const {data} = await api.get(`rls/getAllStudentsRlScenarioInfoByScenarioId/${scenarioId}`);
    return data;
  },
  async getAllRlScenarioInfosByStudent(owner) {
    const {data} = await api.get(`rls/getAllRlScenarioInfosByStudent/${owner}`);
    return data;
  },
  async getAllRlScenarioInfosByAllClassStudents(owners) {
    const {data} = await api.get(`rls/getAllRlScenarioInfosByAllClassStudents/${owners}`);
    return data;
  },
  async updateScenarioInfoById(id, obj) {
    const { data } = await api.put(`rls/updateScenarioInfoById/${id}`, obj);
    return data;
  },
  async updateRlScenarioInfoScoreById(id, obj) {
    const { data } = await api.put(`rls/updateRlScenarioInfoScoreById/${id}`, obj);
    return data;
  },
  async deleteScenarioInfoById(id) {
    const { data } = await api.delete(`rls/deleteScenarioInfoById/${id}`);
    return data;
  },
  async deleteAllRlScenarioInfoByScenarioId(scenarioId) {
    const { data } = await api.delete(`rls/deleteAllRlScenarioInfoByScenarioId/${scenarioId}`);
    return data;
  },
};

export const tutorialApi = {
  async getSections(subj, room) {
    const {data} = await api.get(`tutorial/s/${subj}/${room}`);
    return data;
  },
  async getSectionById(id) {
    const {data} = await api.get(`tutorial/s/${id}`);
    return data;
  },
  async addSection(obj) {
    const { data } = await api.post(`tutorial/s/`, obj);
    return data;
  },
  async updateSection(id, obj) {
    const { data } = await api.put(`tutorial/s/${id}`, obj);
    return data;
  },
  async deleteSection(id) {
    const { data } = await api.delete(`tutorial/s/${id}`);
    return data;
  },

  async getBoards(parent, status = 0) {
    const {data} = await api.get(`tutorial/b/${parent}/${status}`);
    return data;
  },
  async getBoardById(id) {
    const {data} = await api.get(`tutorial/b/${id}`);
    return data;
  },
  async addBoard(obj) {
    const { data } = await api.post(`tutorial/b/`, obj);
    return data;
  },
  async updateBoard(id, obj) {
    const { data } = await api.put(`tutorial/b/${id}`, obj);
    return data;
  },
  async updateBoardData(obj) {
    const { data } = await api.put(`tutorial/b/`, obj);
    return data;
  },
  async deleteBoard(id) {
    const { data } = await api.delete(`tutorial/b/${id}`);
    return data;
  },
};

export default api;
