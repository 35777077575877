import React, { useState, useEffect } from 'react';
import { Modal, Button, Checkbox } from 'rlabui';
import './VideoEditor.scss';

const VideoEditorDbExport = ({ showConfirmDlg, handleNo, videoList, handleYes }) => {
    const [selected, setSelected] = useState([]);

    useEffect(() => {
        if (!showConfirmDlg) return;
        const sel = [];
        for (let i = 0; i < videoList.length; i++) sel.push(false);
        setSelected(sel);
    }, [showConfirmDlg, videoList]);

    useEffect(() => {
        if (!showConfirmDlg) setSelected([]);
    }, [showConfirmDlg]);

    const handleChangeСB = (ind) => {
        const sel = [...selected];
        sel[ind] = !sel[ind];
        setSelected(sel);
    };

    const checkSelected = (ind) => {
        return selected[ind];
    };

    const hasSelected = () => {
        for (let i = 0; i < selected.length; i++) if (selected[i]) return true;
        return false;
    };

    const doHandleYes = () => {
        handleYes([...selected]);
    };

    return (
        <Modal
            className="print"
            visible={showConfirmDlg}
            size={'xs'}
        >
            <Modal.Head
                modalClose={handleNo}
                title="Выберите видео для сохранения измерений"
            ></Modal.Head>
            <Modal.Body>
                {videoList.map((item, ind) => (
                    <div key={'cbelem' + ind}>
                        <Checkbox
                            label={item.videoName}
                            key={'inp' + ind}
                            value={ind}
                            name={item.videoName}
                            checked={checkSelected(ind)}
                            onChange={(e) => handleChangeСB(e.target.value)}
                        />
                    </div>
                ))}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    onClick={doHandleYes}
                    disabled={!hasSelected()}
                >
                    Сохранить измерения
                </Button>
                <Button
                    border={true}
                    onClick={handleNo}
                >
                    Нет
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
export default VideoEditorDbExport;
