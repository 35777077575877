import React, {useState, useEffect} from 'react';
import {Modal} from 'rlabui';
import socket from "../../../socket";
import {storageClass} from "../../../redux/slices/storage";
import {useSelector} from "react-redux";
import { isAuth } from "../../../helpers/auth";
import { getNamesByLabWorkId } from "../../practicum/lw_utils";
import { QUESTION_TYPES, getOnlineTestById, getYesNoOpts, getDurationList } from "../../OnlineTest/ot_utils";
import { getDropdown } from "../../ui/utils/gen_utils";
import { _isOnlineTest, _isLabWork, _isVirtLab, getClassInfo, getLabInfo, 
    getOnlineTestInfo, getButtons } from "./md_utils";
import {labWorkInfoApi, onlineTestInfoApi, labWorkRunApi, onlineTestRunApi, 
    vrLabRunApi, activeLabWorkApi, vrLabInfoApi} from "../../../api/api";
import {setPracticumTestInfo} from "../utils/ServerInfo";
import ss from './ModalBegin.module.scss';
import Warning from '../Warning';

const ModalInitiateTest = ({ showModal, setShowModal, workId, testType, history, isOtRun = true }) => {
    const [isLabWork, setIslabWork] = useState(false);
    const [isOnlineTest, setIsOnlineTest] = useState(false);
    const [isVirtLabGame, setIsVirtLabGame] = useState(false);
    const [contentList, setContentList] = useState([]);
    const [onlineTestTitle, setOnlineTestTitle] = useState('');
    const [onlineTestDescr, setOnlineTestDescr] = useState('');
    const [timeLimitEnabled, setTimeLimitEnabled] = useState(0);
    const [timeLimitInMinutes, setTimeLimitInMinutes] = useState(5);
    const [isRandomOrder, setIsRandomOrder] = useState(true);
    const [isAutoEstimateType, setIsAutoEstimateType] = useState(false);
    const [hasFreeWritingQuestions, setHasFreeWritingQuestions] = useState(false);
    
    const documentClass = useSelector(storageClass);

    useEffect(() => {
        setIslabWork(_isLabWork(testType));
        setIsOnlineTest(_isOnlineTest(testType));
        setIsVirtLabGame(_isVirtLab(testType));
    }, [testType]);

    useEffect(() => {
		const getOtData = async () => {
			const data = await getOnlineTestById(workId);

            setOnlineTestTitle(data.title);
            setOnlineTestDescr(data.description);
            setContentList(getNamesByLabWorkId(data.subjectId + '|' + data.sectionId + '|' + data.labId).split('|'));
            setTimeLimitEnabled(!!data.timeLimitEnabled);
            setTimeLimitInMinutes(data.timeLimitInMinutes !== 0 ? data.timeLimitInMinutes : 5);
            setIsRandomOrder(data.isRandomOrder);
            const hasFreeWriting = !!data.questions.find(item => Number(item.questionType) === QUESTION_TYPES.FREE_WRITING);
            setHasFreeWritingQuestions(hasFreeWriting);
            setIsAutoEstimateType(hasFreeWriting ? false : data.isAutoEstimateType);
        };

        if (!workId) return;
        if (isLabWork || isVirtLabGame) {
            setContentList(getNamesByLabWorkId(workId).split('|'));
        } else if (isOnlineTest) {
            getOtData();
        }
    }, [isLabWork, isOnlineTest, isVirtLabGame, testType, workId]);

    const addAllStudentsVrLab = async (owners, room, workId) => {
        const ownerList = owners.split('|');
        const lab = workId.split('|')[2];
        const vrLabInd = Number(lab.substring(lab.length-2));

        for (let i = 0; i < ownerList.length; i ++) {
            const result = await vrLabInfoApi.getVrLabInfoByOwnerIdByVrLabInd(ownerList[i], vrLabInd);
            if (result.data.length === 0) { //add only new records
                await vrLabInfoApi.addInitVrLabInfoByOwner({
                    owner: ownerList[i],
                    room: room,
                    vrLabInd: vrLabInd,
                });
            }
        }
    };

    const addAllStudentsLWI = async (owners, room, labWorkId, lwRunId) => {
        const labworktest = {pageInd: -1,isComplete: false,};
        const [subjectId, sectionId, labId] = labWorkId.split('|');
        const body = {
            owners: owners,
            room: room,
            lwRunId: lwRunId,
            subjectId: subjectId,
            sectionId: sectionId,
            labId: labId,
            pageInd: labworktest.pageInd !== undefined ? labworktest.pageInd : -1,
            isComplete: labworktest.isComplete ? labworktest.isComplete : false, 
            score: null
        };
        await labWorkInfoApi.initClassUsersLabWorkInfo(body);
    };

    const addAllStudentsOTI = async (owners, room, onlineTestId, otRunId) => {
        const body = {
            owners: owners,
            room: room,
            otRunId: otRunId,
            onlineTest: onlineTestId,
            answers: [],
            isComplete: false,
            score: null,
        };
    
        await onlineTestInfoApi.addAllClassUsersOnlineTestInfos(body);
    };

    const addActiveTest = async (test) => {
        //Find ALL old orphan records and delete them (living more than 1 day):
        const data = await activeLabWorkApi.getActiveLabWorkByTeacher(test.owner, test.room)
            .then (res => res.data)
            .catch(err => console.log('err=', err));
            
        if (data && data.length > 0) {
            for (let i = 0; i < data.length; i ++) {
                const today = new Date();
                const recDate = new Date(data[i].createdAt);
                recDate.setDate(recDate.getDate() + 1);
                
                if (recDate < today) {
                    await activeLabWorkApi.deleteOrphanActiveLabWork(data[i]._id); //clean obsolete records
                }
            }
        }
                                            
        //create active record: 
        await activeLabWorkApi.addActiveLabWork(test);
    };    

    const getTestData = (testRunId) => {
        const startTime = (new Date()).valueOf();

        const testData = {
            owner: isAuth()._id,
            teacherName: isAuth().name,
            room: documentClass._id,
            labWorkId: workId,
            testType: testType, //OT - 0, LW - 1, VIRT Game - 2
            timeLimitEnabled: timeLimitEnabled, //only for OT
            timeLimitInMinutes: timeLimitInMinutes,  //only for OT
            isRandomOrder: isRandomOrder,  //only for OT
            isAutoEstimateType: isAutoEstimateType,  //only for OT
            startTime: startTime,
            testRunId: testRunId, //it's the id of running test. for optics game it is null
        };
        return testData;
    };

    const handleStartTest = () => {
        const runTest = async () => {
            //0 - ot preview:
            if (isOnlineTest && !isOtRun) {
                history.push('/testview/' + workId);
                setShowModal(false);
                return;
            }

            //1 - create run-record:
            let result;

            if (isLabWork) {
                result = await addLabWorkRun(isAuth()._id, documentClass._id, workId);
            } else if (isOnlineTest) {
                result = await addOnlineTestRun(isAuth()._id, documentClass._id, workId);
            } else { //if (isVirtLabGame) {
                result = await addVrLabRun(documentClass._id, workId);
            }
            const testRunId = result._id;

            //2 - Create all records for students who should take part in the test:
            const userIds = documentClass.classUsers
                .filter(item => item.role === 0)
                .map(item => item._id).join('|');

            //3 - create info-records:
            let isOk = false;
            try {
                if (isLabWork) {
                    await addAllStudentsLWI(userIds, documentClass._id, workId, testRunId);
                } else if (isOnlineTest) {
                    await addAllStudentsOTI(userIds, documentClass._id, workId, testRunId);
                } else { //if (isVirtLabGame) {
                    await addAllStudentsVrLab(userIds, documentClass._id, workId);
                }
                isOk = true;
            } catch (error) {
                console.log(error);
            } finally {
                if (isOk) {
                    const testData = getTestData(testRunId);
                    setPracticumTestInfo(testData);
                
                    socket.emit('BE-send-test', {roomId: documentClass._id, test: testData});
                    addActiveTest(testData);//для пользователей, которые в данный момент offline

                    const path = isLabWork ? '/reviewtest/lw' : isVirtLabGame ? 
                        '/reviewtest/vrlab': '/reviewtest/ot';
                    history.push(path);
                    setShowModal(false);
                }
            }
        };

        runTest();
    }; 
    
    const addLabWorkRun = async (owner, room, labWorkId) => {
        const [subjectId, sectionId, labId] = labWorkId.split('|');

        const body = {
            owner: owner,
            room: room,
            subjectId: subjectId,
            sectionId: sectionId,
            labId: labId,
        };

        const data = await labWorkRunApi.addLabWorkRun(body)
            .then (res => res)
            .catch(err => console.log('err=', err));
        return data;
    };

    const addOnlineTestRun = async (owner, room, onlineTest) => {
        const body = {
            owner: owner,
            room: room,
            onlineTest: onlineTest,
        };
    
        return await onlineTestRunApi.addOnlineTestRun(body)
            .then (res => res)
            .catch(err => console.log('err=', err));
    };

    const addVrLabRun = async (room, vrLabInd) => {
        const body = {
            room: room,
            vrLabInd: Number(vrLabInd),
        };
    
        const data = await vrLabRunApi.addVrLabRun(body)
            .then (res => res.data)
            .catch(err => console.log('err=', err));
        return data;
    };

    if (!showModal) {
        return false;
    }

    return (
        <Modal className={ss.root} visible={showModal} size={!isOnlineTest ? 'md' : 'md'}>
            <Modal.Head 
                modalClose={() => setShowModal(false)} 
                title={'Начать '  + (isLabWork ? 'лабораторную работу' : 
                    isVirtLabGame ? 'игру' : isOtRun ? 'онлайн тест' : 'предпросмотр онлайн теста')} 
            />
            <Modal.Body>
                <div className={ss.body}>
                    {getClassInfo(documentClass.className, documentClass.classUsers.filter(user => user.role === 0).length)}
                    
                    <>
                        {(isLabWork || isVirtLabGame) && getLabInfo(contentList)}
                        
                        {isOnlineTest && (
                        <>
                            {getOnlineTestInfo(onlineTestTitle, onlineTestDescr)}

                            {hasFreeWritingQuestions &&
                                <div className={ss.row}>
                                    <Warning>Этот тест содержит вопросы, требующие свободного изложения, и может оцениваться только вручную.</Warning>
                                </div>
                            }

                            <div className={ss.row}>
                                <div className={ss.col}>
                                    <div className={ss.label}>Автоматическая оценка</div>
                                    {getDropdown(!hasFreeWritingQuestions ? getYesNoOpts() : [{label: 'Нет', value: 'no'}], 
                                        isAutoEstimateType ? 'yes' : 'no', value => setIsAutoEstimateType(value === 'yes'), 
								        '', '', 'auto01')}
                                </div>
                                <div className={ss.col}>
                                    <div className={ss.label}>Случайный порядок вопросов</div>
                                    {getDropdown(getYesNoOpts(), isRandomOrder ? 'yes' : 'no', value => setIsRandomOrder(value === 'yes'), 
    							        '', '', 'randomim01')}
                                </div>
                            </div>

                            <div className={ss.row}>
                                <div className={ss.col}>
                                    <div className={ss.label}>Ограничение по времени</div>
                                    {getDropdown(getYesNoOpts(), !!timeLimitEnabled ? 'yes' : 'no', value => setTimeLimitEnabled(value === 'yes'), 
								        '', '', 'limit01')}
                                </div>
                                <div className={ss.col}>
                                    <div className={ss.label}>Продолжительность теста</div>
                                    {getDropdown(getDurationList(), timeLimitInMinutes, value => setTimeLimitInMinutes(Number(value)), 
								        '', '', 'dur01')}
                                </div>
                            </div>
                        </>
                        )}
                    </>
                </div>
            </Modal.Body>
            <Modal.Footer>
                {getButtons(handleStartTest, setShowModal, true, testType, isOtRun)}
            </Modal.Footer>
        </Modal>
    )
};

export default ModalInitiateTest;
