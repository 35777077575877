import React, { useState, useEffect } from "react";
import {FEATURES} from "../../../api/config";
import LabWorks from '../../practicum/LabWorks';
import OnlineTests from '../../OnlineTest/OnlineTests';
import {ContentWrap, ContentHead, Content, ContentBody} from '../ContentParts';
import SectionTabs from "../../ui/SectionTabs";
import Footer from '../Footer';
import {hasFeature, isAuth} from "../../../helpers/auth";
import {PRACTICUM_TEST_OPT, isActiveTestView} from "../../ui/utils/ServerInfo";
import {useSelector, useDispatch} from "react-redux";
import {getOtPiInfoCounters} from "./db_utils";
import {loadPIResearches} from "../../../redux/slices/lists";
import {WS_TYPE} from "../../PhysicalInvest/pi_utils";
import {storageOrg, storageClass} from "../../../redux/slices/storage";
import DashboardLicense from "./DashboardLicense";
import DashboardReviewTest from "./DashboardReviewTest";
import "./Dashboard.scss";

export const DashboardPracticum = (props) => {
	const [tabs, setTabs] = useState(null);

  const documentOrg = useSelector(storageOrg);
  const documentClass = useSelector(storageClass);
	const {lists: {pi}} = useSelector(state => state);
  const dispatch = useDispatch();

	useEffect(() => {
    const status = isAuth().role > 0 ? WS_TYPE.CHECK_IN_PROGRESS : WS_TYPE.WORK;
		dispatch(loadPIResearches(documentClass._id, status));
	}, [dispatch, documentClass._id]);

	useEffect(() => {
    const prepareTabs = async () => {
      const [otValue, piValue] = await getOtPiInfoCounters(documentClass._id, pi.researches);

      const _tabs = FEATURES.practicum.sub.map((s) => ({
        name: FEATURES[s].title + " >", 
        icon: FEATURES[s].icon, 
        tooltip: FEATURES[s].tooltip,
        to: (([PRACTICUM_TEST_OPT.OT,PRACTICUM_TEST_OPT.LW].includes(s) && isAuth().role === 1) ? 
          '/practicum/' + s : FEATURES[s].to), disabled: !hasFeature(documentOrg, s),
        notify: s === 'ot' ? otValue : s === 'pi' ? piValue : null
      }));

      setTabs(_tabs);
    };

    prepareTabs();
  }, [documentOrg, documentClass, pi]);

  const opt = props.match.params.opt;
  if (opt && !hasFeature(documentOrg, opt)) {
    return <DashboardLicense {...props}/>;
  }

  if (isActiveTestView(opt) && opt) {
    return <DashboardReviewTest {...props}/>;
  }

  return (
    <ContentWrap>
      {{
        'ot': <OnlineTests {...props} />,
        'lw': <LabWorks {...props} />,
        undefined: <>
          <ContentHead title="Задания"/>
          <Content background={false}>
            <ContentBody padding={false}>
              {tabs && <SectionTabs {...props} tabs={tabs} />}
            </ContentBody>
          </Content>
        </>
      }
        [opt]}
      <Footer  {...props}/>
    </ContentWrap>
  );
}

export default DashboardPracticum;
