import React, {useState, useEffect} from "react";
import { Warning } from '../ui';
import { isAuth } from '../../helpers/auth';
import {useSelector} from "react-redux";
import { getInitiatedTasks, getNotebookInfoData } from './task_utils';
import {toast} from "react-toastify";
import {storageClass} from "../../redux/slices/storage";
import {notebookApi, notebookInfoApi} from "../../api/api";
import {NB_HOME_WORK_STATUS, NB_STATES, NB_ACTION, NB_ACT, getSubjectById, getInitialSettings, 
    getInitialRun, getFinalWarning, getNotebookById, addNotebook, getNotifMsg} from './nb_utils';
import NotebookAddPage from './NotebookAddPage';
import NotebookAddSettings from './NotebookAddSettings';
import NotebookAddRun from './NotebookAddRun';
import {getNbStorage, setNbStorage} from "./NotebookStorage";
import ProgressBar from './ProgressBar';
import { Back } from "../template/ContentParts";
import "../template/Dashboard/Dashboard.scss";
import nb from "./Notebooks.module.scss";
import "./Notebooks.scss";

const NotebookPages = ({history, homeWorkId, setHomeWorkId, 
        homeWorkTaskSamples, setHomeWorkTaskSamples, currTaskInd, handleActions, 
        isDisabledTemplate, setIsDisabledTemplate, isSetRunTime,
        nbState, setNbState, isSaveData, setIsSaveData, isEditable, setIsEditable,
        isTaskChanged, setIsTaskChanged, forceSaveTask, setForceSaveTask, 
        isSettingChanged, setIsSettingChanged, forceSaveSettings, setForceSaveSettings, 
        isRunChanged, setIsRunChanged, forceSaveRun, setForceSaveRun, 
        actionType, setSubjectId
    }) => {
    const [homeWorkStatus, setHomeWorkStatus] = useState(NB_HOME_WORK_STATUS.CATALOG);
    const [homeWorkTitles, setHomeWorkTitles] = useState(null);
    const [homeWorkSettings, setHomeWorkSettings] = useState(null);
    const [homeWorkRun, setHomeWorkRun] = useState(null);
    const [forceComplete, setForceComplete] = useState(false);
    const [moveToStep, setMoveToStep] = useState(-1);
    const [isAutoPrompt, setIsAutoPrompt] = useState(true);
    const [isNbLoaded, setIsNbLoaded] = useState(false);
    const [objVersion, setObjVersion] = useState(0);

    const documentClass = useSelector(storageClass);

    useEffect(() => {
        if (homeWorkId) return;
        const data = getNbStorage();
        if (!data.homeWorkId) return;
        setHomeWorkTitles(data);
        setHomeWorkTaskSamples([]);
        setHomeWorkSettings(getInitialSettings());
        setHomeWorkRun(getInitialRun());
        setSubjectId(data?.subjectId);
    },[homeWorkId, setHomeWorkTaskSamples, setSubjectId]);

	useEffect(() => {
		const fetchDbData = async (nbId) => {
			const data = await getNotebookById(nbId);
            setIsNbLoaded(true);
            setHomeWorkStatus(data.homeWorkStatus);
            setHomeWorkTitles(data.titles);
            setSubjectId(data.titles.subjectId);
            setNbStorage({...data.titles, homeWorkId: nbId});
            setHomeWorkTaskSamples(data.taskSamples);
            setHomeWorkSettings(data.settings);
            const _isDisabledTemplate = !data.owner && isAuth().role < 3;
            setIsDisabledTemplate(_isDisabledTemplate);
            setIsEditable(isAuth().role === 3 || 
                (data.homeWorkStatus === NB_HOME_WORK_STATUS.CATALOG && !_isDisabledTemplate));
            setObjVersion(data.__v);

            let runOpts = getInitialRun(data.run);
            if (isSetRunTime) {  //если хотим запустить старое задание, установим начало на сегодня
                const startDate = data.run?.runFullDate ? new Date(data.run.runFullDate) : null;
                const todayDate = new Date();
                const today = todayDate.valueOf();
                const start = startDate ? startDate.valueOf() : null;
                if (!start || start < today) {
                    runOpts = getInitialRun();
                }
            }
            setHomeWorkRun(runOpts);

            if (actionType === NB_ACTION.UNDEFINED) { //after initial loading the selected nb
                const taskInd = currTaskInd === -1 && data.taskSamples.length > 0 ? 0 : currTaskInd;
                const act = getActionTypeBySampleNumber(actionType, data.taskSamples);
                handleActions({type: act, payload: taskInd});
            }
        };

        if (isNbLoaded || !homeWorkId) return;
        fetchDbData(homeWorkId);
	},[currTaskInd, homeWorkId, isNbLoaded, setSubjectId, isSetRunTime, handleActions, actionType,
       setHomeWorkTaskSamples, setIsDisabledTemplate, setIsEditable]);

    const getActionTypeBySampleNumber = (type, taskSamples) => 
        taskSamples.length === 0 || type === NB_ACTION.TASK_SAVE_ADD ? NB_ACTION.TASK_ADD : NB_ACTION.TASK_EDIT;

    useEffect(() => {
        const getHomeWork = (_homeWorkStatus, _homeWorkTitles, _homeWorkTaskSamples, _homeWorkSettings, _homeWorkRun, room) => {
            const homework = {
                homeWorkStatus: _homeWorkStatus,
                titles: _homeWorkTitles,
                taskSamples: _homeWorkTaskSamples,
                settings: _homeWorkSettings,
            };
    
            if (isAuth().role < 3) {
                homework.room = room;
                homework.run = _homeWorkRun;
            }
    
            return homework;
        };
    
        const createStudentTasks = async (_homeWorkId, homeWorkData) => {
            const owners = documentClass.classUsers.filter(item => item.role === 0).map(item => item._id);
            const commonTasks = getInitiatedTasks(homeWorkData);
            
            for (let i = 0; i < owners.length; i ++) {
                const notebookInfoData = getNotebookInfoData(_homeWorkId, homeWorkData, commonTasks, 
                    documentClass._id, owners[i]);
                await notebookInfoApi.addNotebookInfo(notebookInfoData);
            }
        };
    
        const doAddHomeWork = async (obj, msg) => {
            setIsSaveData(false);
            const result = await addNotebook(obj);
            setHomeWorkId(result._id);
            toast.success(msg); 
            setIsNbLoaded(false); //to reload the object
        };
    
		const doUpdateHomeWorkById = async (_homeWorkId, obj, msg) => {
            await notebookApi.updateNotebookById(_homeWorkId, 
                    {...obj, homeWorkStatus: homeWorkStatus, __v: objVersion})
            .then((result) => {
                if (msg) toast.success(msg); 

                if (!forceComplete) {
                    //1. модифицируем задание
                    setIsSaveData(false);
                    setIsNbLoaded(false); //to reload the object
                    const act = getActionTypeBySampleNumber(actionType, result.taskSamples);
                    handleActions({type: act, payload: currTaskInd});
                } else {
                    //создаем задачи для студентов только для этого статуса и роли
                    if (homeWorkStatus === NB_HOME_WORK_STATUS.PROGRESS && isAuth().role < 3) {
                        createStudentTasks(homeWorkId, result, documentClass);
                    }
                    //завершаем
                    history.push('/notebooks/1/' + result.titles.subjectId);
                }
            }).catch((err) => {
                toast.error(err.response.data.errors);
            });
        };

        if (isSaveData) {
            const homeWorkData = getHomeWork(homeWorkStatus, homeWorkTitles, homeWorkTaskSamples, 
                homeWorkSettings, homeWorkRun, documentClass?._id);
            homeWorkData.homeWorkStatus = NB_HOME_WORK_STATUS.CATALOG;
            const msg = getNotifMsg(forceComplete, homeWorkStatus);

            if (homeWorkId === null) { //add the home work:
                doAddHomeWork(homeWorkData, msg);
            } else { //update the home work:
                doUpdateHomeWorkById(homeWorkId, homeWorkData, msg);
            }
        }
	},[handleActions, history, isSaveData, homeWorkId, setHomeWorkId, currTaskInd, setHomeWorkTaskSamples,
        forceComplete, homeWorkStatus, homeWorkTitles, homeWorkTaskSamples, homeWorkSettings, homeWorkRun, 
        setIsSaveData, documentClass, actionType, objVersion]);

    const handleBack = () => {
        if (nbState === NB_STATES.PAGE) 
            //history.push('/notebooks/' + NB_ACT.SHOW_ALL_NBS);
            history.push('/notebooks/1/' + homeWorkTitles.subjectId);
        else if (nbState === NB_STATES.SETTINGS)
            setNbState(NB_STATES.PAGE);
        else if (nbState === NB_STATES.RUN)
            setNbState(NB_STATES.SETTINGS);
    };

    const doSetHomeWorkSettings = (settings) => {
        setHomeWorkSettings(settings);
        setIsSaveData(true);
    };

    const doSetHomeWorkRun = (run) => {
        setHomeWorkRun(run);
        setIsSaveData(true);
    };

    const getCurrTask = () => {
        if (!homeWorkId || actionType !== NB_ACTION.TASK_EDIT || currTaskInd === -1)
            return null;
        return homeWorkTaskSamples[currTaskInd];
    };

    const handleProgress = (step) => {
        if (nbState === step) return;

        if ((nbState === NB_STATES.PAGE && !isTaskChanged) ||  
            (nbState === NB_STATES.SETTINGS && !isSettingChanged) || 
            (nbState === NB_STATES.RUN && !isRunChanged)) {
            setNbState(step);
            return;
        }

        if (isTaskChanged) setForceSaveTask(true);
        if (isSettingChanged) setForceSaveSettings(true);
        if (isRunChanged) setForceSaveRun(true);
        setMoveToStep(step); // it will force to jump to the page as soon as all changes are saved
    };

    useEffect(() => { //after saving all changes, jump to selected page
        if (moveToStep === -1 || isTaskChanged || isSettingChanged || isRunChanged) return;
        setNbState(moveToStep);
        setMoveToStep(-1);
    },[moveToStep, isTaskChanged, isSettingChanged, isRunChanged, setNbState]);

    return  (
        <>
            <div className={nb.nb__header}>
                <Back onClick={handleBack} 
                    text={nbState === NB_STATES.PAGE ? 'Вернуться на Главную' : 'Назад' } 
                    icon="back" margin="bottom" 
                />

                <div className="nb__title nb__title_start">
                    {homeWorkTitles && 
                    <>{homeWorkTitles.homeWorkName}<span>
                        <b>{getSubjectById(homeWorkTitles.subjectId)}</b> 
                        {<b>/</b>} {homeWorkTitles?.homeWorkItem}
                    </span> 
                    </>}
                </div>
                <div className={nb.nb__description}>
                    {nbState === NB_STATES.PAGE ? 
                        (isAuth().role < 3 ? 'Соберите задание. Можно использовать готовые наборы вопросов или создать свои.' : "Создайте шаблон.") : 
                    nbState === NB_STATES.SETTINGS ? 
                        ("Настройте параметры " + (isAuth().role < 3 ? 'задания' : 'шаблона задания')) : 
                        'Настройте параметры запуска задания'}
                </div>

                <ProgressBar 
                    progress={nbState} 
                    handleProgress={handleProgress} 
                    isDisabledTemplate={isDisabledTemplate}
                />

                {homeWorkStatus === NB_HOME_WORK_STATUS.PROGRESS && <Warning>{getFinalWarning(homeWorkRun)}</Warning>}  
            </div>

            {nbState === NB_STATES.PAGE &&
                <NotebookAddPage 
                    currTaskInd={currTaskInd}
                    currTask={getCurrTask()}
                    setNbState={setNbState}
                    actionType={actionType}
                    isEditable={isEditable}
                    handleActions={handleActions}
                    subjectId={homeWorkTitles?.subjectId}

                    isTaskChanged={isTaskChanged}
                    setIsTaskChanged={setIsTaskChanged}
                    forceSaveTask={forceSaveTask}
                    setForceSaveTask={setForceSaveTask} 

                    isAutoPrompt={isAutoPrompt}
                    setIsAutoPrompt={setIsAutoPrompt}
                    isDisabledTemplate={isDisabledTemplate}
                />
            }

            {nbState === NB_STATES.SETTINGS &&
                <NotebookAddSettings 
                    setNbState={setNbState}
                    homeWorkSettings={homeWorkSettings}
                    setHomeWorkSettings={doSetHomeWorkSettings}
                    isSettingChanged={isSettingChanged}
                    setIsSettingChanged={setIsSettingChanged}
                    forceSaveSettings={forceSaveSettings}
                    setForceSaveSettings={setForceSaveSettings}
                    isEditable={isEditable}
                    setForceComplete={setForceComplete}
                    isDisabledTemplate={isDisabledTemplate}
                />
            }

            {nbState === NB_STATES.RUN &&
                <NotebookAddRun 
                    setNbState={setNbState}
                    setHomeWorkStatus={setHomeWorkStatus}
                    homeWorkRun={homeWorkRun}
                    setHomeWorkRun={doSetHomeWorkRun}
                    isRunChanged={isRunChanged}
                    setIsRunChanged={setIsRunChanged}
                    forceSaveRun={forceSaveRun}
                    setForceSaveRun={setForceSaveRun}
                    setForceComplete={setForceComplete}
                    isEditable={isEditable}
                    homeWorkTaskSamples={homeWorkTaskSamples}
                />
            }
        </>
    );
}

export default NotebookPages;
