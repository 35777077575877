import React, {useState, useEffect, useRef} from "react";
import {NB_STATES,  NB_ACTION} from "./nb_utils";
import { Icon } from '../ui';
import { isAuth } from '../../helpers/auth';
import {toast} from "react-toastify";
import {getTemplatesBySubjectId} from "./templates";
import "../template/Dashboard/Dashboard.scss";
import "../CalcModels/CalcModel.scss";

const NotebookAside = ({nbState, actionType,
                        homeWorkTaskSamples, setHomeWorkTaskSamples, isDisabledTemplate,
                        isEditable, isTaskChanged, isSettingChanged, isRunChanged, 
                        handleActions, currTaskInd, subjectId}) => {
    const [templates, setTemplates] = useState([]);

    const dragItem = useRef(); //https://rootstack.com/en/blog/how-do-i-use-drag-and-drop-react
    const dragOverItem = useRef();

	useEffect(() => {
        if (subjectId === undefined) return;
        setTemplates(getTemplatesBySubjectId(subjectId).templates);
	},[subjectId]);

    const dragStart = (e, position) => {
        dragItem.current = position;
        //console.log(e.target.innerHTML);
      };
     
      const dragEnter = (e, position) => {
        dragOverItem.current = position;
        //console.log(e.target.innerHTML);
      };
     
      const drop = (e) => {
        const copyListItems = [...homeWorkTaskSamples];
        const dragItemContent = copyListItems[dragItem.current];
        copyListItems.splice(dragItem.current, 1);
        copyListItems.splice(dragOverItem.current, 0, dragItemContent);
        dragItem.current = null;
        dragOverItem.current = null;
        setHomeWorkTaskSamples(copyListItems);
      };

    const canDoAction = () => {
        let msg = '';
        if (isTaskChanged) msg = "Сохраните измененную задачу и повторите действие."
        else if (isSettingChanged) msg = "Сохраните измененные установки и повторите действие."; 
        else if (isRunChanged) msg = "Сохраните измененные параметры запуска и повторите действие."; 
        
        if (msg) toast.warn(msg);
        return !msg;
    };
    const handleTaskSelect = ind => {
        if (!canDoAction()) return;
        if (actionType === NB_ACTION.TASK_ADD) 
            toast.warn('Завершите редактирование задания.');
        else {
            handleActions({type: NB_ACTION.TASK_EDIT, payload: ind});
        }
    };

    const handleTaskAdd = () => {
        if (!canDoAction() || !isEditable) return;
        //const payload = !!homeWorkTaskSamples && homeWorkTaskSamples.length;
        handleActions({type: NB_ACTION.TASK_ADD}); //, payload: payload});
    };
    const handleTaskCopy = (ind) => {
        if (!canDoAction()) return;
        handleActions({type: NB_ACTION.TASK_COPY, payload: ind});
    };
    const handleTaskDelete = (ind) => {
        if (!canDoAction()) return;
        handleActions({type: NB_ACTION.TASK_DELETE, payload: ind});
   };

    const getPageOptions = (taskInd) => {
        return (
            <div className="doing">
                <div className="doing__toggle">
                    <Icon name="dots"/>
                </div>
                <ul className="doing__list">
                    <li className="doing__item" onClick={() => handleTaskCopy(taskInd)}>
                        <Icon name="copy-paste" />
                        <span>Копировать</span>
                    </li>
                    <li className="doing__item" onClick={() => handleTaskDelete(taskInd)}>
                        <Icon name="trash" />
                        <span>Удалить</span>
                    </li>
                </ul>
            </div>
        );		
    };

    const getNewPage = () => { //it's always active
        return (
            <div className="structureList__item active" key={'npg01'}>
                <div className="structureList__title" key={'npg02'}>
                    Новая задача
                </div>

                <div className="structureList__page" key={'npg03'}>
                    <div className="structureList__info"  key={'npg04'}> 
                        <span>Введите параметры задачи</span>
                    </div>
                    <div className="structureList__action" key={'npg06'}>
                    </div>
                </div>
            </div>
        );
    };
      
    const getThePage = (item, ind, taskInd) => {
        const pageName = templateId => {
            return templates?.find(item => item.templateId === templateId)?.name;
        };

        const pageGroupAmount = (amount) => {
            const num = amount % 10;
            const txt = (num === 1 ? 'пример' : (num > 4 || num === 0) ? 'примеров' : 'примера'); 
            return '' + amount + ' ' + txt;
        };

        const isActive = actionType === NB_ACTION.TASK_EDIT && ind === currTaskInd;

        return (
            <div className={"structureList__item " + (isActive ? 'active' : '')} 
                key={'pg01'+ind} 
                onClick={() => handleTaskSelect(ind)}
                onDragStart={(e) => dragStart(e, ind)}
                onDragEnter={(e) => dragEnter(e, ind)}
                onDragEnd={drop}
                draggable={isAuth().role === 3 || !isDisabledTemplate}
            >
                <div className="structureList__title" key={'pg02'+ind}>
                    Задача {taskInd+1}
                </div>

                <div className="structureList__page" key={'pg03'+ind}>
                    <div className="structureList__info" key={'pg23'+ind}>
                        <span key={'pg04'+ind}>{pageName(item.templateId)}</span>
                        <span key={'pg05'+ind}>{pageGroupAmount(item.groupAmount)}</span>
                    </div>
                    {(isAuth().role === 3 || !isDisabledTemplate) &&
                    <div className="structureList__action" key={'pg06'+ind}>
                        {getPageOptions(ind)}
        
                        <div className="drag" key={'pg07'+ind}>
                            <Icon name="drag"/>
                        </div>
                    </div>
                    }
                </div>
            </div>
        );
    };

    const getPages = () => {
        if (!homeWorkTaskSamples) return <></>;

        if (homeWorkTaskSamples.length === 0) {
            return getNewPage();    
        }

        if (actionType === NB_ACTION.TASK_EDIT) {
            return <>{homeWorkTaskSamples.map((item, ind) => getThePage(item, ind, ind))}</>;
        } 

        const pages = [];
        for (let ind = 0; ind < currTaskInd && ind < homeWorkTaskSamples.length; ind ++) 
            pages.push(getThePage(homeWorkTaskSamples[ind], ind, ind));

        pages.push(getNewPage()); //show empty page

        for (let ind = Math.max(currTaskInd, 0); ind < homeWorkTaskSamples.length; ind ++) 
            pages.push(getThePage(homeWorkTaskSamples[ind], ind, ind + 1));

        return <>{pages}</>;
    };

    return (
        <div className="structureList">
            {nbState === NB_STATES.PAGE &&
                <div className={"structureList__item structureList__firstPage " + (!isEditable ? 'disabled' : '')}>
                    <div onClick={handleTaskAdd} className={"structureList__add " + (!isEditable ? 'disabled' : '')}>
                        <Icon name="plus_bold"/>Добавить страницу
                    </div>                    
                </div>
            }

            <div className="structureList__scroll">
                <div className="structureList__content">
                    {getPages()}
                </div>
            </div>
        </div>
    );
}

export default NotebookAside;
