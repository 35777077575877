import React from 'react';
import ss from './FilterByDate.module.scss';
import DateTimePicker from '../DatePicker/DateTimePicker';
// import Icon from '../Icon';

// const START_DATE_PICKER_ID = 'START_DATE_PICKER_ID';
// const FINISH_DATE_PICKER_ID = 'FINISH_DATE_PICKER_ID';

const FilterByDate = ({
    startDate,
    setStartDate,
    finishDate,
    setFinishDate
}) => {
    return (
        <div className={ss.root}>
        
            <div className={ss.root__item}>
                <div className={ss.root__label}>Дата с</div>
                <div className={ss.root__input}>
                    <DateTimePicker
                        startDate={startDate} 
                        setStartDate={setStartDate} 
                        // id={START_DATE_PICKER_ID}
                    />
                </div>
                {/* <Icon 
                    name={'calendar'} 
                    onClick={() => document.getElementById(START_DATE_PICKER_ID)?.click()} 
                /> */}
            </div>

            <div className={ss.root__item}>
                <div className={ss.root__label}>По</div>
                <div className={ss.root__input}>
                    <DateTimePicker 
                        startDate={finishDate} 
                        setStartDate={setFinishDate} 
                        // id={FINISH_DATE_PICKER_ID}
                    />
                </div>
                {/* <Icon 
                    name={'calendar'} 
                    onClick={() => document.getElementById(FINISH_DATE_PICKER_ID)?.click()} 
                /> */}
            </div>
        </div>
    )
};
export default FilterByDate;

