import React from "react";
import Footer from '../Footer';
import LabWorkShow from '../../practicum/LabWorkShow';
import OnlineTestExecute from '../../OnlineTest/OnlineTestExecute';
import {PRACTICUM_TEST_OPT, getPracticumTestInfo} from "../../ui/utils/ServerInfo";
import {Content, ContentWrap} from "../ContentParts";
import {useSelector} from "react-redux";
import {storageOrg} from "../../../redux/slices/storage";
import {hasFeature} from "../../../helpers/auth";
import DashboardLicense from "./DashboardLicense";
import "./Dashboard.scss";
import "../../practicum/TutorialSelectTab.scss";

export const DashboardTestExecute = (props) => {
  const documentOrg = useSelector(storageOrg);

  //added isStarted to initiate the OT
  const testInfo = getPracticumTestInfo();
  //const opt = props.match.params.opt;
  const isOnlineTest = !!testInfo && testInfo.testType ===  0;

  if (!(hasFeature(documentOrg, PRACTICUM_TEST_OPT.OT) || hasFeature(documentOrg, PRACTICUM_TEST_OPT.LW)))
    return <DashboardLicense {...props}/>;

  return (
    <ContentWrap>
      {isOnlineTest ?
        <OnlineTestExecute {...props} isStarted={true} />
        :
        <LabWorkShow {...props} />
      }
      <Footer {...props}/>
    </ContentWrap>
  );
}

export default DashboardTestExecute;
