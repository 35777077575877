import React, { useState, useEffect } from 'react';
import {Modal} from 'rlabui';
import {PRACTICUM_TEST_TYPE, PRACTICUM_TEST_OPT} from "../utils/ServerInfo";
import {useSelector} from "react-redux";
import {getNamesByLabWorkId } from "../../practicum/lw_utils";
import {getOnlineTestById} from "../../OnlineTest/ot_utils";
import {storageClass} from "../../../redux/slices/storage";
import { _isOnlineTest, _isLabWork, _isVirtLab, getClassInfo, getLabInfo, 
    getOnlineTestInfo, getButtons } from "./md_utils";
import ss from './ModalBegin.module.scss';

const ModalRunStudentTestDlg = ({ showModal, setShowModal, testInfo, history, setTakePartInTest }) => {
    const [isLabWork, setIslabWork] = useState(false);
    const [isOnlineTest, setIsOnlineTest] = useState(false);
    const [isVirtLabGame, setIsVirtLabGame] = useState(false);
    const [virtLabGameNumber, setVirtLabGameNumber] = useState(undefined);
    const [contentList, setContentList] = useState(null);
    const [onlineTestTitle, setOnlineTestTitle] = useState('');
    const [onlineTestDescr, setOnlineTestDescr] = useState('');
    const [timeLimitEnabled, setTimeLimitEnabled] = useState(false);
    const [timeLimitInMinutes, setTimeLimitInMinutes] = useState(0);
    const documentClass = useSelector(storageClass);
    
    useEffect(() => {
		const getOTData = async () => {
   			const data = await getOnlineTestById(testInfo.labWorkId);
            setOnlineTestTitle(data.title);
            setOnlineTestDescr(data.description);
            setTimeLimitEnabled(testInfo.timeLimitEnabled); //take from testInfo, not from data!
            setTimeLimitInMinutes(testInfo.timeLimitInMinutes);  //take from testInfo, not from data!
        };

        if (!testInfo || !testInfo.labWorkId) return;

        const isLW = _isLabWork(testInfo.testType);
        const isOT = _isOnlineTest(testInfo.testType);
        const isVG = _isVirtLab(testInfo.testType);
        setIslabWork(isLW);
        setIsOnlineTest(isOT);
        setIsVirtLabGame(isVG);

        if (isLW) {
            setContentList(getNamesByLabWorkId(testInfo.labWorkId).split('|'));
        } else if (isOT) {
            getOTData();
        } else if (isVG) {
            //извлечем индекс лаб.работы по оптике:
            const gameInd = Number(testInfo.labWorkId.split('|')[2].replace('lab', ''));
            setVirtLabGameNumber(gameInd);
            setContentList(getNamesByLabWorkId(testInfo.labWorkId).split('|'));
        }
    }, [testInfo]);

    const handleStartTest = () => {
        setTakePartInTest(true); //the student started the test
        const path = !isVirtLabGame ? 
            ('/ot/' + (testInfo.testType === PRACTICUM_TEST_TYPE.ONLINE_TEST ? 
                PRACTICUM_TEST_OPT.OT : PRACTICUM_TEST_OPT.LW)) : 
            '/vrlab/' + virtLabGameNumber;
        history.push(path);
        setShowModal(false);
    };  

    return (
        <Modal className={ss.root} visible={showModal} size="md">
            <Modal.Head 
                // modalClose={() => {}} 
                title={isLabWork ? 'Новая лабораторная работа' : isOnlineTest ? 'Новый онлайн тест' : 'Новая игра'} 
            /> 
            <Modal.Body>
                <div className={ss.body}>
                    {getClassInfo(documentClass.className, documentClass.classUsers.filter(user => user.role === 0).length)}

                    {(isLabWork || isVirtLabGame) && getLabInfo(contentList)}

                    {isOnlineTest && (
                        <>
                            {getOnlineTestInfo(onlineTestTitle, onlineTestDescr)}

                            <div className={ss.row}>
                                <span>Тест ограничен по времени</span>
                                <span>{timeLimitEnabled? 'Да' : 'Нет'}</span>    
                            </div>
                            {!!timeLimitEnabled && (
                                <div className={ss.row}>
                                    <span>Продолжительность теста</span>
                                    <span>{timeLimitInMinutes}<b>&nbsp;минут</b></span>    
                                </div>
                            )}
                        </>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer align="left">
                {getButtons(handleStartTest, setShowModal, false, testInfo.testType, true)}
            </Modal.Footer>
        </Modal>
    )
};

export default ModalRunStudentTestDlg;
