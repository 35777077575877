import React, {useState, useEffect} from "react";
import {tutorialApi} from "../../api/api";
import {toast} from "react-toastify";
import {FilterByText, Grid, Icon} from "../ui";
import {Back, Content, ContentBody, ContentHead, ContentHeader} from "../template/ContentParts";
import ModalConfirmDialog from "../ui/ModalDialogs/ModalConfirmDialog";
import ss from "./TutorialBoardGrid.module.scss"
import {ErrorBoundary, TldrawImage} from "@tldraw/tldraw";
import {useSelector} from "react-redux";
import {storageOptions} from "../../redux/slices/storage";
import {FEATURES} from "../../api/config";
import {Button} from "rlabui";
import {isAuth} from "../../helpers/auth";

const TutorialBoardGrid = (props) => {
  const {opt, sid} = props.match.params;
  const options = useSelector(storageOptions);
  const [boards, setBoards] = useState([]);
  const [section, setSection] = useState([]);
  const [idBeDeleted, setIdBeDeleted] = useState(undefined);
  const [showConfirmDlg, setShowConfirmDlg] = useState(false);
  const [search, setSearch] = useState('');

  const loadSection = () => tutorialApi.getSectionById(sid).then(data => setSection(data));
  const loadBoards = () => tutorialApi.getBoards(sid).then(data => setBoards(data));
  const readOnly = (i) => ((isAuth().role === 3) || (i?.owner?._id === isAuth()._id)) ? 0 : 1;

  useEffect(() => {
    loadSection();
    loadBoards();
    // eslint-disable-next-line
  }, [sid]);

  const handleRequestDeleteTest = id => {
    setIdBeDeleted(id);
    setShowConfirmDlg(true);
  };

  const handleDeleteTestNo = id => {
    setShowConfirmDlg(false);
    setIdBeDeleted(undefined);
  };

  const handleDeleteTestYes = () => {
    tutorialApi.deleteBoard(idBeDeleted)
    .then(() => {
      setShowConfirmDlg(false);
      setIdBeDeleted(undefined);
      loadBoards();

      toast.success("Пособие удалено.");
    }).catch((err) => {
      toast.error(err.response.data.errors);
    });
  };

  const onSubmit = (data) => {
    if (data._id) {
      tutorialApi.updateBoard(data._id, data)
      .then((res) => {
        toast.success("Пособие '" + res.name + "' обновлено.");
        loadBoards();
        props.showNameSettings({showModal: false});
      }).catch((err) => {
        toast.error(err.response.data.errors);
      });
    } else {
      tutorialApi.addBoard({...data, parent:sid})
      .then((res) => {
        toast.success("Пособие '" + res.name + "' создано.");
        loadBoards();
        props.showNameSettings({showModal: false});
      }).catch((err) => {
        toast.error(err.response.data.errors);
      });
    }
  };

  const goBack = () => props.history.push(FEATURES.tutorial.to + '/' + opt);
  const navigate = (bid) => props.history.push(FEATURES.tutorial.to + '/' + opt + '/' + sid + '/' + bid);

  return (
    <>
      <ContentHead flex={false}>
        <Back onClick={goBack} icon="back" margin="bottom"/>
        <div className="cor_content__title">Каталог пособий {section.name} ({section.desc})</div>
      </ContentHead>
      <Content>
        <ContentHeader>
          <div className="cor-net__row">
            <div className="cor-net__col col-4">
              <FilterByText
                placeholder={'Введите название пособия'}
                value={search}
                setSearch={setSearch}
              />
            </div>
            <div className="cor-net__col col-grow"></div>
            <div className="cor-net__col">
              {!readOnly(section) && <Button size="small"
                      onClick={() => props.showNameSettings({showModal: true, isAdd: true, onSubmit})}>Создать
                пособие</Button>}
            </div>
          </div>
        </ContentHeader>
        <ContentBody>
          <Grid className={ss.grid}>
            {boards.filter(f => f.name.toLowerCase().includes(search.toLowerCase()) ||
              f.desc?.toLowerCase().includes(search.toLowerCase())).map((b, i) => (
              <Grid.Col key={i} className={ss.column}>
                <div className={ss.preview} onClick={() => navigate(b._id)}><ErrorBoundary
                  onError={(e) => console.log(e)} fallback={() => null}>
                  <TldrawImage snapshot={JSON.parse(b.data ?? '{}')} theme={options.theme}/>
                </ErrorBoundary></div>
                <div className={ss.content}>
                  <div className={ss.head}>
                    <span className={ss.name} onClick={() => navigate(b._id)}>{b.name}</span>
                    <div className={ss.controls}>
                      {!readOnly(b) && <Icon
                        name="edit"
                        title="Редактировать"
                        onClick={() => props.showNameSettings({showModal: true, data: b, onSubmit})}
                      />}
                      {!readOnly(b) && <Icon
                        name="trash"
                        title="Удалить"
                        onClick={() => handleRequestDeleteTest(b._id)}
                      />}
                    </div>
                  </div>
                  <div className={ss.desc}>
                    <span>
                      {b.desc.length > 65 ? b.desc.substr(0, 65) + '...' : b.desc}
                    </span>
                  </div>
                </div>

              </Grid.Col>
            ))}
          </Grid>
        </ContentBody>
      </Content>

      {showConfirmDlg && <ModalConfirmDialog
        showConfirmDlg={showConfirmDlg}
        handleNo={handleDeleteTestNo}
        handleYes={handleDeleteTestYes}
        question={'Вы действительно хотите удалить это пособие?'}
      />
      }
    </>
  );
}

export default TutorialBoardGrid;
