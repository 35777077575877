import React, {useState, useEffect} from "react";
import {useSelector} from "react-redux";
import {storageClass} from "../../redux/slices/storage";
import {shouldReorderDates} from './rt_utils';
import {chkItemText, chkItemDate} from "../ui/utils/gen_utils";
import Table from "../ui/Table/Table";
import {Tabs} from '../ui';
import SelectFilterOptions  from "./SelectFilterOptions";
import {printOnlyDate} from "../../helpers/text";
import {onlineTestApi, onlineTestInfoApi, onlineTestRunApi} from "../../api/api";
import ModalConfirmDialog from "../ui/ModalDialogs/ModalConfirmDialog";
import {isAuth} from "../../helpers/auth";
import {OT_TAB_STATE, OT_RESULTS} from "../OnlineTest/ot_utils";
import "../template/Dashboard/Dashboard.scss";
import "./ViewLabWorks.scss";
import "./ReviewActiveLabWork.scss";
import { Content, ContentBody, ContentHead, ContentHeader } from "../template/ContentParts";

// таблица выаолненных онлайн тестов
const ViewAllOnlineTestRuns = ({ history }) => {
	const [runTesFullTableList, setRunTesFullTableList] = useState([]);
	const [filteredTableData, setFilteredTableData] = useState([]);
	const [testFilter, setTestFilter] = useState('');
	const [startDate, setStartDate] = useState(null);
	const [finishDate, setFinishDate] = useState(null);
    const [showConfirmDelete, setShowConfirmDelete] = useState('');
    const [idBeDeleted, setIdBeDeleted] = useState('');
    const [isUpdated, setIsUpdated] = useState(false);

	const documentClass = useSelector(storageClass);

    useEffect(() => {
		const prepareTable = async () => {
			//1 - get test run ids
			const result0 = await onlineTestRunApi
				.getOnlineTestRunsByClassOwners([isAuth()._id], documentClass._id);
			const runTestList = result0.data;

			//2 - get OTs by run ids
			const otIDs = runTestList.map(item => item.onlineTest).join('|');
			const result = await onlineTestApi.getAllOnlineTestsByIDs(otIDs);
			const selectedOnlineTests = result.data;

			//3 - get info for each run id
			const list = [];
	
			for (let i = 0; i < runTestList.length; i++) {
				const runTest = runTestList[i];
				const ot = selectedOnlineTests.find(item => item._id === runTest.onlineTest);
				
				if (ot) {
					//4 - get info how many non-checked tests in each run:
					const otis = await onlineTestInfoApi
						.getTeacherNonCheckedOtTasksByRunId(documentClass._id, runTest._id);
					const nonCheckedTests = otis.data.filter(item => item.answers.length > 0);

					const item = {
						name: ot.title, 
						description: ot.description,
						date: runTest.createdAt, 
						nonChecked: nonCheckedTests.length > 0 ? nonCheckedTests.length : '',
						actions: '', 
						id: runTest._id};
					list.push(item);
				}
			}
	
			setRunTesFullTableList(list);
		};

		prepareTable();
		if (isUpdated) setIsUpdated(false);
    }, [documentClass._id, isUpdated]); //runTestList

	useEffect(() => {
		if (shouldReorderDates(startDate, finishDate)) { //check startDate <= finishDate
			setStartDate(finishDate);
			setFinishDate(startDate);
		}
    }, [startDate, finishDate]);
	
	useEffect(() => {
		//filtering:
		const list = [];
		for (let i = 0; i < runTesFullTableList.length; i ++) {
			const row = runTesFullTableList[i];

			if ((chkItemText(row, 'name', testFilter) || chkItemText(row, 'description', testFilter)) && 
				chkItemDate(row.date, startDate, true) && chkItemDate(row.date, finishDate, false)) {
				const newRow = {...row};
				newRow.date = printOnlyDate(row.date);
				list.push(newRow);
			}
		}
		setFilteredTableData(list);
    }, [runTesFullTableList, testFilter, startDate, finishDate]);

	const handleRunningTestDetails = id => {
		history.push('/reviewtest/ot/' + id);
	};

	const handleConfirmDeleteRunningTest = id => {
		setIdBeDeleted(id);
		setShowConfirmDelete(true);
	};

	const handleDeleteRunningTest = () => {
		const deleteOnlineTestInfoByOtRunId = async (otRunId) => {
			setShowConfirmDelete(false);
			await onlineTestInfoApi.deleteAllOnlineTestInfosByRunId(otRunId); //delete ot results:
			await onlineTestRunApi.deleteOnlineTestRunById(otRunId); //delete ot runs:
			setIdBeDeleted('');
			setIsUpdated(true);
		};

		deleteOnlineTestInfoByOtRunId(idBeDeleted);
	};

	const specialCellStyle = (item, rowInd, colInd, row) => {
		const NON_CHECKED_RES_COL = 3;
		if (colInd === NON_CHECKED_RES_COL) return {color: '#C96203'};
		return {};
	};

	const getTableHeader = () => {
		return [
			{column: 'Название', name: 'name', style: { width: '20%'} }, 
			{column: 'Описание', name: 'description', style: { width: '25%'} }, 
			{column: 'Дата', name: 'date', style: { width: '15%'} }, 
			{column: 'Непроверенные результаты', name: 'nonChecked', style: { width: '20%'} }, 
			{column: 'Действия с тестом', name: 'actions', style: { width: '20%'} }, 
			{column: 'id', name: 'id', style: { width: '0%'} }, 
		];
	};

	return (
		<>
			<ContentHead title="Онлайн тесты" />
			<Content>
				<ContentHeader>
					<Tabs>
						{OT_TAB_STATE.map(tab => (
							<Tabs.Item 
								active={tab.status === OT_RESULTS}
								onClick={() => history.push(tab.path)}
								key={tab.status}
							>
								{tab.name}
							</Tabs.Item>
						))}
					</Tabs>
				</ContentHeader>

				<ContentHeader>
					<SelectFilterOptions
						textPrompt={'Введите название или описание онлайн теста'}
						testFilter={testFilter}
						setTestFilter={setTestFilter}
						startDate={startDate}
						setStartDate={setStartDate}
						finishDate={finishDate}
						setFinishDate={setFinishDate}
					/>
				</ContentHeader>
				
				<ContentBody>
					<Table
						table={{
							header: getTableHeader(), 
							data: filteredTableData,
							specialCellStyle: specialCellStyle
						}}
						sort={{
							hasSorting: true, 
							initSortInd: -3, 
						}}
						person={undefined}
						actions={{
							handleShowDetails: id => handleRunningTestDetails(id),
							textShowDetails: 'Смотреть результаты',
							handleDelete: id => handleConfirmDeleteRunningTest(id),
						}}								
					/>
				</ContentBody>
			</Content>

			{showConfirmDelete &&
		   	<ModalConfirmDialog
				showConfirmDlg={showConfirmDelete} 
				handleNo={() => setShowConfirmDelete(false)}
				handleYes={handleDeleteRunningTest}
				question={'Вы действительно хотите удалить результаты этого теста?'}
			/>
			}
		</>
	);
}

export default ViewAllOnlineTestRuns;
