import React, {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {storageOptions} from "../../../redux/slices/storage";
import {isAuth} from "../../../helpers/auth";
import {toast} from "react-toastify";
import Footer from '../Footer';
import ShowSimpleFigureDialog from "../../Instruments/ShowSimpleFigureDialog";
import VoltageGenerator from "../../Instruments/VoltageGenerator";
import {VideoEditor} from '../../../rlab/src/components/VideoEditor';
import {videoEditorApi} from "../../../api/api";
import {VIDEO_SOURCE, SCREEN_MODE, getImageNumber} from '../../../rlab/src/components/VideoEditor/utils';
import {setCor} from '../../../rlab/src/actions/actions';
import {setVideoEditorOptions} from '../../../rlab/src/actions/videoeditor';
import {loadVEFiles, uploadVEFile, deleteVEFileByName, getSectionTab} from './db_utils';
import { Content, ContentWrap, ContentBody, ContentHead, ContentDesc, Back, ContentTitle } from "../ContentParts";
import "./Dashboard.scss";
import "../../practicum/TutorialSelectTab.scss";

export const DashboardInstrumentSelect = (props) => {
    const [showModalSimpleDlg, setShowModalSimpleDlg] = useState(false);
    const [videoName, setVideoName] = useState(undefined);
    const [currMode, setCurrMode] = useState(SCREEN_MODE.DO_LOAD_VIDEO);
    const [fireVideoMode, setFireVideoMode] = useState(false);
    const [selectedFrameInd, setSelectedFrameInd] = useState(-1);

	const _id = props.match.params.id;
    const {theme} = useSelector(storageOptions);
	const isLightMode = theme !== "dark";
    const dispatch = useDispatch();
    const {lists: {files}} = useSelector(state => state);

	useEffect(() => {
		loadVEFiles(dispatch);
	}, [dispatch]);

	useEffect(() => {
		const clearExportedImage = async (videoDbRecord) => {
			if (!videoDbRecord) return;
			const frames = videoDbRecord.frames;
			for (let i = 0; i < frames.length; i++)
				await deleteVEFileByName(dispatch, frames[i].frameName);
		};
	
		const addUpdateVideoDataToDb = async (videoDbRecord, setVideoDbRecord, videoDbData) => {
			if (videoDbRecord) {
				await videoEditorApi.updateVideoEditorById(videoDbRecord._id, videoDbData);
				await videoEditorApi.getVideoEditorById(videoDbRecord._id);
			} else {
				await videoEditorApi.addVideoEditor(videoDbData);
			}
		};
	
		const updateVideoOptions = (dispatch, videoSource, files, fireVideoMode) => {
			const options = {
				videoSource: videoSource,
				uploadVEFile: (files, file, setLoadedFileName) => uploadVEFile(files, file, setLoadedFileName),
				getNewFiles: (allCollectedFiles, fileNameList) => getNewFiles(allCollectedFiles, fileNameList),
				files: files,
				dispatch: dispatch,
				owner: isAuth()._id,
				showMsg: (type, text) => showMsg(type, text),
				addUpdateVideoDataToDb: (videoDbRecord, setVideoDbRecord, videoDbData) => addUpdateVideoDataToDb(videoDbRecord, setVideoDbRecord, videoDbData),
				clearExportedImage: (videoDbRecord) => clearExportedImage(videoDbRecord),
				setVideoName: setVideoName, //set video name to show it in the title
				setCurrMode: setCurrMode,
				fireVideoMode: fireVideoMode, 
				setFireVideoMode: setFireVideoMode,
				setSelectedFrameInd: setSelectedFrameInd,
			};
	
			dispatch(setVideoEditorOptions(options));
		};
	
		if (_id === "videofile" || _id === "videocamera") {
            dispatch(setCor(true));
			const videoSource = _id === "videofile" ? VIDEO_SOURCE.VIDEOFILE : 
								_id === "videocamera" ? VIDEO_SOURCE.CAMERA : VIDEO_SOURCE.DB;
			updateVideoOptions(dispatch, videoSource, files, fireVideoMode);
		}
	}, [_id, dispatch, files, fireVideoMode]);

	const getNewFiles = (allCollectedFiles, fileNameList) => {
		//we need to find all files from fileNameList that are not added to the collection
		if (allCollectedFiles.length === 0 || fileNameList.length === 0) return [];
		const checkedFiles = [];

		for (let k = 0; k < fileNameList.length; k ++) {
			const answerFileName = fileNameList[k].name;

			let found = false;
			for (let i = 0; i < allCollectedFiles.length; i ++) {
				const file = allCollectedFiles[i];
				const fileName = file.Key.split('/')[1];    
				if (fileName === answerFileName) 
					found = true;
			}
			if (!found)
				checkedFiles.push(answerFileName); 
		}

		return checkedFiles; //the files exist in the collection
	};
	
	const showMsg = (type, text) => {
		if (type === 0) toast.success(text);
		else if (type === 1) toast.warn(text);
		else toast.error(text);
	};

	const getHeaderBox = (_history, id) => {
		const getTitle = (id) => {
			const title = id === "geometry" ? "Иллюстратор объектов" : 
			id === "voltage" ? "Генератор напряжения" : "Видеоанализ";
			const title2 = title === "Видеоанализ" && videoName ? videoName : title;

			const frameName = 'Кадр '+ getImageNumber(selectedFrameInd);
			return currMode !== SCREEN_MODE.SHOW_FRAME ? title2 : frameName;
		};
		const backText = currMode === SCREEN_MODE.SHOW_VIDEO ? 'Выйти из просмотра' : 
						 currMode === SCREEN_MODE.SHOW_FRAME ? 'Вернуться к видео' : 'Назад';
		const handleBack = () => {
			if (currMode === SCREEN_MODE.SHOW_FRAME) {
				setFireVideoMode(true);
			} else if (id === 'video') {
				props.history.push('/instrument/video');
			} else {
				props.history.push('/instruments');
			}
		};

		return (
		  <>
			<ContentHead flex={false}>
		  		<Back icon="back" 
					  margin="bottom" 
					  onClick={handleBack} 
					  text={backText}
				/>
				<ContentTitle>
					{getTitle(id)}
				</ContentTitle>
			</ContentHead>
			{id === 'video' && 
				<ContentDesc desc="Выберите источник видео" />
			}
		  </>
		); 
	  };

	const getDetails = () => {
		const  handleShowModalSimpleDlg = () => {
			setShowModalSimpleDlg(true);
		};
	
		const handleVideoSelectTab = (ind) => {
			props.history.push('/instrument/' + ind);
		};
	
		if (_id === "geometry") {
			return (
				<div className="InitSectionTabs">
					{getSectionTab('geometry', 'Простые геометрические фигуры', 'block', 
						'Простые геометрические фигуры', handleShowModalSimpleDlg)}
		        </div>
			);	
		} else if (_id === "voltage") {
			return <VoltageGenerator isLightMode={isLightMode} />;
		} else if (_id === "video") {
			return (
				<div className="InitSectionTabs">
					{getSectionTab('videofile', 'Открыть видео с компьютера >', 'mac', 
						'Анализ параметров объектов на видео с компьютера', handleVideoSelectTab)}
					{getSectionTab('videocamera', 'Записать видео с микроскопа >', 'microscope', 
						'Анализ параметров объектов на видео из микроскопа', handleVideoSelectTab)}
					{getSectionTab('videolib', 'Загрузить видео из картотеки >', 'folder', 
						'Анализ параметров объектов на видео из картотеки', handleVideoSelectTab)}
				</div>
			);	//
		}

		return <VideoEditor isLightMode={isLightMode} />;
	};

    return (
		<>
			<ContentWrap>
				{getHeaderBox(props.history, _id)}
				<Content>
					<ContentBody scrolled={false}>
						{getDetails()}
					</ContentBody>
				</Content>
				<Footer  {...props}/>
			</ContentWrap>

			{showModalSimpleDlg && <ShowSimpleFigureDialog
				showModal={showModalSimpleDlg} 
				setShowModal={setShowModalSimpleDlg} 
			/>}
		</>
    );
};

export default DashboardInstrumentSelect;

// export const VIDEO_SOURCE = {
//     UNDEFINED: 0,
//     VIDEOFILE: 1,
//     CAMERA: 2,
//     LIBRARY: 3,
//     DB: 4,
// };
