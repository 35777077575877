import React, {useState, useEffect, useLayoutEffect} from 'react';
import {useSelector} from "react-redux";
import {storageOptions} from "../../redux/slices/storage";
import {Tldraw} from "tldraw";
import {assets, unfurlBookmarkUrl} from "../TlDraw/assets";
import {createTLStore, getSnapshot, loadSnapshot} from "@tldraw/tldraw";
import {debounce} from "@tldraw/utils";
import {tutorialApi} from "../../api/api";
import {toast} from "react-toastify";
import {isAuth} from "../../helpers/auth";
import {Back, Content, ContentBody, ContentHead} from "../template/ContentParts";

const TutorialBoardEdit = (props) => {
  const options = useSelector(storageOptions);
  const {opt, sid, bid} = props.match.params;
  const [editor, setEditor] = useState();
  const [board, setBoard] = useState();

  const [store, setStore] = useState();
  const [loadingState, setLoadingState] = useState({status: 'loading'})

  const readOnly = () => !((isAuth().role === 3) || (board?.owner?._id === isAuth()._id));
  const goBack = async () => {
    await saveData();
    props.history.push('/tutorial/' + opt + '/' + sid);
  }
  const saveData = async () => {
    if (readOnly()) return;
    const snapshot = getSnapshot(store);
    await tutorialApi.updateBoardData({_id: bid, data: JSON.stringify(snapshot)})
    .catch((err) => toast.error(err.response.data.errors));
  }

  useLayoutEffect(() => {
    if (!store) return;
    setLoadingState({status: 'loading'})
    if (board.data) {
      try {
        const snapshot = JSON.parse(board.data);
        loadSnapshot(store, snapshot);
        setLoadingState({status: 'ready'});
      } catch (error) {
        setLoadingState({status: 'error', error: error.message});
      }
    } else {
      setLoadingState({status: 'ready'});
    }

    const cleanupFn = store.listen(debounce(saveData, 2_000));
    return () => cleanupFn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store]);

  useEffect(() => {
    tutorialApi.getBoardById(bid).then((b) => {
      setBoard(b)
      setStore(createTLStore(b.owner ? {assets} : {}));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    editor?.user.updateUserPreferences({colorScheme: options.theme})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options.theme]);

  return (<>
      <ContentHead flex={false}>
        <Back onClick={goBack} icon="back" margin="bottom"/>
        <div className="cor_content__title">Пособие {board?.name} ({board?.desc})</div>
      </ContentHead>
      <Content>
        <ContentBody>
          {loadingState.status === 'error' ? <div className="tldraw__editor">
              <h2>Error!</h2>
              <p>{loadingState.error}</p>
            </div>
            : loadingState.status === 'error' ? <div className="tldraw__editor">
                <h2>Loading</h2>
              </div>
              : <Tldraw
                store={store}
                readonly={readOnly}
                onMount={(editor) => {
                  setEditor(editor);
                  editor.registerExternalAssetHandler('url', unfurlBookmarkUrl);
                  editor.updateInstanceState({ isReadonly: readOnly() })
                }}
              />}
        </ContentBody>
      </Content></>
  );
};
export default TutorialBoardEdit;
